import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { UsersContext } from '../../Contexts/UsersContext'

const UserModal = () => {

    const { createUser, newUser, setNewUser } = useContext(UsersContext);

    return (
        <div>
            <input type="checkbox" id="usersModal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box w-11/12 max-w-5xl bg-blue-900">
                    <h3 className="font-bold text-lg mb-5 text-indigo-100">CREATE NEW USER</h3>
                    <div className="flex relative my-5">
                        <input className="product-modal-input" type="search" placeholder="USERNAME" value={newUser ? newUser.username : ''} onChange={(e) => setNewUser({...newUser, username: e.target.value})} />
                        <FontAwesomeIcon icon={solid('user')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                    </div>
                    <div className="flex relative my-5">
                        <input className="product-modal-input" type="password" placeholder="PASSWORD" value={newUser ? newUser.password : ''} onChange={(e) => setNewUser({...newUser, password: e.target.value})} />
                        <FontAwesomeIcon icon={solid('lock')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                    </div>
                    <div className="flex relative my-5">
                        <input className="product-modal-input" type="search" placeholder="EMAIL" value={newUser ? newUser.email : ''} onChange={(e) => setNewUser({...newUser, email: e.target.value})} />
                        <FontAwesomeIcon icon={solid('envelope')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                    </div>
                    <div className='flex justify-center'>
                        <div className="form-control mx-10">
                            <label className="label cursor-pointer">
                                <span className="label-text text-vangold mx-5">IS ADMIN</span>
                                <input type="checkbox" className="checkbox checkbox-info" checked={newUser ? newUser.isAdmin : false} onChange={(e) => setNewUser({...newUser, isAdmin: e.target.value === 'on' ? true : false})} />
                            </label>
                        </div>
                        <div className="form-control mx-10">
                            <label className="label cursor-pointer">
                                <span className="label-text text-vangold mx-5">CAN CREATE</span>
                                <input type="checkbox" className="checkbox checkbox-info" checked={newUser ? newUser.canCreate : false} onChange={(e) => setNewUser({...newUser, canCreate: e.target.value === 'on' ? true : false})} />
                            </label>
                        </div>
                        <div className="form-control mx-10">
                            <label className="label cursor-pointer">
                                <span className="label-text text-vangold mx-5">CAN UPDATE</span>
                                <input type="checkbox" className="checkbox checkbox-info" checked={newUser ? newUser.canUpdate : false} onChange={(e) => setNewUser({...newUser, canUpdate: e.target.value === 'on' ? true : false})} />
                            </label>
                        </div>
                        <div className="form-control mx-10">
                            <label className="label cursor-pointer">
                                <span className="label-text text-vangold mx-5">CAN DELETE</span>
                                <input type="checkbox" className="checkbox checkbox-info" checked={newUser ? newUser.canDelete : ''} onChange={(e) => setNewUser({...newUser, canDelete: e.target.value === 'on' ? true : false})} />
                            </label>
                        </div>
                    </div>
                    <div className="modal-action">
                        <label htmlFor="usersModal" className="modal-action-btn" onClick={() => createUser()}>CREATE</label>
                        <label htmlFor="usersModal" className="modal-action-btn">CLOSE</label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserModal