import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FailuresContext } from '../../../Contexts/FailuresContext';

const EditFailureModal = () => {

    const { selectedFailure, setSelectedFailure, updateFailure } = useContext(FailuresContext);

    return (
        <div>
            <input type="checkbox" id="editFailureModal" className="modal-toggle" />
            <div className="modal modal-bottom sm:modal-middle">
                <div className="modal-box bg-blue-900">
                    <h3 className="font-bold text-lg mb-5 text-indigo-100">EDIT FAILURE</h3>
                    <div className="flex relative">
                        <input className="modal-input" type="search" placeholder="FAILURE PHENOMENON" value={selectedFailure ? selectedFailure.phenomenon : ''} onChange={(e) => setSelectedFailure({ ...selectedFailure, phenomenon: e.target.value })} />
                        <FontAwesomeIcon icon={solid('grip-vertical')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                    </div>
                    <div className="flex relative">
                        <input className="modal-input" type="search" placeholder="CAUSE" value={selectedFailure ? selectedFailure.cause : ''} onChange={(e) => setSelectedFailure({ ...selectedFailure, cause: e.target.value })} />
                        <FontAwesomeIcon icon={solid('grip-vertical')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                    </div>
                    <div className="flex relative">
                        <input className="modal-input" type="search" placeholder="SOLUTION" value={selectedFailure ? selectedFailure.solution : ''} onChange={(e) => setSelectedFailure({ ...selectedFailure, solution: e.target.value })} />
                        <FontAwesomeIcon icon={solid('grip-vertical')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                    </div>
                    <div className="modal-action">
                        <label htmlFor="editFailureModal" className="modal-action-btn" onClick={() => updateFailure()}>EDIT</label>
                        <label htmlFor="editFailureModal" className="modal-action-btn">CLOSE</label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditFailureModal