import { createContext, useContext, useState } from "react";
import { ProductsContext } from "./ProductsContext";
import configData from '../config.json'

export const SpecificationsContext = createContext();

const SpecificationsContextProvider = (props) => {

    const apiPath = configData.apiPath;

    const { selectedProduct } = useContext(ProductsContext);

    const [specification, setSpecification] = useState();
    const [specifications, setSpecifications] = useState([]);
    const [selectedSpecification, setSelectedSpecification] = useState();

    const getSpecifications = async () => {
        
        var resp = await fetch(apiPath + 'specifications/get?productID=' + selectedProduct.productID);        
        if (resp.ok) { var data = await resp.json(); setSpecifications(data); }
    }

    const createSpecification = async () => {
        
        specification.productID = selectedProduct.productID;

        var resp = await fetch(apiPath + 'specifications/create', {
            method: 'POST', mode: 'cors', cache: 'no-cache', credentials: 'same-origin',
            headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(specification)
        });

        if (resp.ok) { getSpecifications() } 
    }

    const deleteSpecification = async () => {
        
        var resp = await fetch(apiPath + 'specifications/delete', {
            method: 'DELETE', mode: 'cors', cache: 'no-cache', credentials: 'same-origin',
            headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(selectedSpecification)
        });

        if (resp.ok) { getSpecifications() } 
    }

    return(
        <SpecificationsContext.Provider value={{
            specification, setSpecification,
            specifications, setSpecifications,
            selectedSpecification, setSelectedSpecification,
            getSpecifications, createSpecification,
            deleteSpecification
        }}>
            {props.children}
        </SpecificationsContext.Provider>
    )
}

export default SpecificationsContextProvider;