import React, { useContext, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { DownloadablesContext } from '../../../../Contexts/DownloadablesContext'
import { ProductsContext } from '../../../../Contexts/ProductsContext'

const CreateOperationVideoModal = () => {

    const { downloadable, setDownloadable, setFile, createDownloadable } = useContext(DownloadablesContext);
    const { allProducts } = useContext(ProductsContext);
    const [uploadedFile, setUploadedFile] = useState('');

    const saveFile = (e) => {

        setFile(e.target.files[0]);
        setDownloadable({...downloadable, filename: e.target.files[0].name});
        setUploadedFile(e.target.files[0].name);
    }

    const openFileInput = () => {

        document.getElementById('fileInput').click();
    }

    return (
        <div>
            <input type="checkbox" id="createOperationVideoModal" className="modal-toggle" />
            <div className="modal modal-bottom sm:modal-middle">
                <div className="modal-box bg-blue-900">
                    <h3 className="font-bold text-lg mb-5 text-indigo-100">CREATE NEW OPERATION VIDEO</h3>

                    <input id='fileInput' className="hidden" type="file" placeholder="PRODUCT PICTURE" onChange={(e) => saveFile(e)} />

                    <div className="flex relative mb-5">
                        <input id="uploadPic" value={uploadedFile ? uploadedFile : ''} type="text" className="product-modal-file-input" placeholder="VIDEO FILE" onClick={() => openFileInput()} />
                        <FontAwesomeIcon icon={solid('file-alt')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                    </div>

                    <div className="flex relative">
                        <select className='printers-select' value={downloadable ? downloadable.productID : 0} onChange={(e) => setDownloadable({...downloadable, productID:e.target.value})}>
                            <option value={0} disabled selected>PRODUCT</option>
                            {
                                allProducts && allProducts.map((product) => (
                                    <option value={product.productID} key={product.productID}>{product.productName}</option>
                                ))
                            }
                        </select>
                        <FontAwesomeIcon icon={solid('clone')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                    </div>

                    <div className="flex relative col-start-4">
                        <input className="modal-input" type="search" placeholder="OPERATION VIDEO NAME" value={downloadable ? downloadable.downloadableName : ''} onChange={(e) => setDownloadable({...downloadable, downloadableName: e.target.value})} />
                        <FontAwesomeIcon icon={solid('file')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                    </div>
                    <div className="flex relative col-start-4">
                        <input className="modal-input" type="search" placeholder="OPERATION VIDEO DESCRIPTION"  value={downloadable ? downloadable.downloadableDescription : ''} onChange={(e) => setDownloadable({...downloadable, downloadableDescription: e.target.value})}/>
                        <FontAwesomeIcon icon={solid('clipboard-list')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                    </div>                    
                    <div className="modal-action">
                        <label htmlFor="createOperationVideoModal" className="btn modal-action-btn" onClick={() => createDownloadable('Video')}>CREATE</label>
                        <label htmlFor="createOperationVideoModal" className="btn modal-action-btn">CLOSE</label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateOperationVideoModal