import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FailuresContext } from '../../../Contexts/FailuresContext';

const CreateFailureModal = () => {

    const { failure, setFailure, createFailure } = useContext(FailuresContext);

    return (
        <div>
            <input type="checkbox" id="createFailureModal" className="modal-toggle" />
            <div className="modal modal-bottom sm:modal-middle">
                <div className="modal-box bg-blue-900">
                    <h3 className="font-bold text-lg mb-5 text-indigo-100">CREATE FAILURE</h3>
                    <div className="flex relative">
                        <input className="modal-input" type="search" placeholder="FAILURE PHENOMENON" value={failure ? failure.phenomenon : ''} onChange={(e) => setFailure({ ...failure, phenomenon: e.target.value })} />
                        <FontAwesomeIcon icon={solid('grip-vertical')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                    </div>
                    <div className="flex relative">
                        <input className="modal-input" type="search" placeholder="CAUSE" value={failure ? failure.cause : ''} onChange={(e) => setFailure({ ...failure, cause: e.target.value })} />
                        <FontAwesomeIcon icon={solid('grip-vertical')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                    </div>
                    <div className="flex relative">
                        <input className="modal-input" type="search" placeholder="SOLUTION" value={failure ? failure.solution : ''} onChange={(e) => setFailure({ ...failure, solution: e.target.value })} />
                        <FontAwesomeIcon icon={solid('grip-vertical')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                    </div>
                    <div className="modal-action">
                        <label htmlFor="createFailureModal" className="modal-action-btn" onClick={() => createFailure()}>CREATE</label>
                        <label htmlFor="createFailureModal" className="modal-action-btn">CLOSE</label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateFailureModal