import { createContext, useState } from "react";

export const LoadingContext = createContext();

const LoadingContextProvider = (props) => {
    
    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('LOADING ...');   

    return (
        <LoadingContext.Provider value={{
            loading, setLoading,
            loadingMessage, setLoadingMessage
        }}>
            {props.children}
        </LoadingContext.Provider>
    )

}

export default LoadingContextProvider;