import React, { useContext, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { CategoriesContext } from '../../Contexts/CategoriesContext'
import CategoryRecords from './CategoryRecords'
import ProductsModal from '../Products/ProductModals/ProductsModal'
import EditProductModal from '../Products/ProductModals/EditProductModal'
import HideShowProductModal from '../Products/ProductModals/HideShowProductModal'
import DeleteProductModal from '../Products/ProductModals/DeleteProductModal'
import HideShowCategoryModal from './CategoryModals/HideShowCategoryModal'
import DeleteCategoryModal from './CategoryModals/DeleteCategoryModal'
import EditCategoryModal from './CategoryModals/EditCategoryModal'
import CreateCategoryModal from './CategoryModals/CreateCategoryModal'
import { UsersContext } from '../../Contexts/UsersContext'
import { LoadingContext } from '../../Contexts/LoadingContext'
import Loading from '../Loading/Loading'

const Categories = () => {

    const { loggedUser } = useContext(UsersContext);
    const { getCategories } = useContext(CategoriesContext);
    const { loading } = useContext(LoadingContext);

    useEffect(() => { getCategories(); }, []);

    const openCreateCategoryModal = () => {

        document.getElementById('createCategoryModal').checked = true;
    }

    return (
        loading ? <Loading /> :
        <div>
            <div className='my-5 mx-10 grid md:grid-cols-2 lg:grid-cols-4 gap-5'>
                {
                    loggedUser && loggedUser.canCreate ?
                        <div className="flex relative">
                            <button type="submit" className="printers-button" onClick={() => openCreateCategoryModal()}>
                                <span className='mr-2'>ADD NEW CATEGORY</span>
                                <FontAwesomeIcon icon={solid('plus')} />
                            </button>
                        </div> : null
                }
            </div>
            <CategoryRecords />
            <ProductsModal />
            <EditProductModal />
            <HideShowProductModal />
            <DeleteProductModal />
            <HideShowCategoryModal />
            <DeleteCategoryModal />
            <EditCategoryModal />
            <CreateCategoryModal />
        </div>
    )
}

export default Categories