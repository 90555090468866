import React, { useContext } from 'react';
import { SpecificationsContext } from '../../../Contexts/SpecificationsContext';

const DeleteSpecificationModal = () => {

    const { deleteSpecification } = useContext(SpecificationsContext);

    return (
        <div>
            <input type="checkbox" id="deleteSpecificationModal" className="modal-toggle" />
            <div className="modal modal-bottom sm:modal-middle">
                <div className="modal-box bg-blue-900">
                    <div>
                        <h3 className="font-bold text-md mb-5 text-white">THIS ACTION WILL PERMENANTLY DELETE THIS SPECIFICATION AND IT WILL NO LONGER APPEAR IN THE SPECIFICATIONS LIST</h3>
                        <h3 className="text-md mb-5 text-white">ARE YOU SURE YOU WANT TO DO THIS ?</h3>
                    </div>
                    <div className="modal-action">
                        <label htmlFor="deleteSpecificationModal" className="modal-action-btn" onClick={() => deleteSpecification()}>CONFIRM</label>
                        <label htmlFor="deleteSpecificationModal" className="modal-action-btn">CLOSE</label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteSpecificationModal