import { createContext } from "react";

export const PouchDBContext = createContext();

const PouchDBContextProvider = (props) => {

    var PouchDB = require('pouchdb').default;
    const db = new PouchDB('VanGoldDB');

    const getPersistedUser = async () => {

        const persistedUser = await db.get('persistedUserID');
        return persistedUser;
    }

    const setPersistedUser = async (loggedUser) => {

        const persistedUser = { ...loggedUser, _id: 'persistedUserID' };
        await db.put(persistedUser);
    }

    const removePersistedUser = async (loggedUser) => {

        await db.remove(loggedUser);
    }

    return (
        <PouchDBContext.Provider value={{ setPersistedUser, getPersistedUser, removePersistedUser }}>
            {props.children}
        </PouchDBContext.Provider>
    );
}

export default PouchDBContextProvider;