import React, { useContext } from 'react'
import { PhotosContext } from '../../../Contexts/PhotosContext';

function PhotosGalleryModal() {

    const { displayedPhoto } = useContext(PhotosContext);

    return (
        <div>
            <input type="checkbox" id="photoGalleryModal" className="modal-toggle" />
            <div className="modal bg-slate-200">
                <div className="modal-box w-11/12 max-w-5xl bg-transparent">
                    <figure><img className='mx-auto rounded-md' src={displayedPhoto} alt='PHOTO' /></figure>
                    <div className="modal-action">
                        <label htmlFor="photoGalleryModal" className="btn">CLOSE</label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PhotosGalleryModal