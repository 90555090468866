import React, { useContext } from 'react'
import Product from './Product';
import { LoadingContext } from '../../Contexts/LoadingContext'
import Loading from '../Loading/Loading';

const Products = ({ products }) => {

    const { loading } = useContext(LoadingContext);

    return (
        loading ? <Loading /> :
        <div className='relative'>
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-5 py-5">
                {
                    products && products.length > 0 ?
                        products.map((product) => (
                            <Product product={product} key={product.productID} />
                        ))
                        : <div className='font-bold text-md my-10 col-span-4 text-center'>THERE ARE NO PRODUCTS</div>
                }
            </div>            
        </div>
    )
}

export default Products