import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { SpecificationsContext } from '../../Contexts/SpecificationsContext'
import { UsersContext } from '../../Contexts/UsersContext'

const Specification = ({ specification }) => {

    const { loggedUser } = useContext(UsersContext);
    const { setSelectedSpecification } = useContext(SpecificationsContext);

    const openDeleteSpecificationModal = () => {

        document.getElementById('deleteSpecificationModal').checked = true;
        setSelectedSpecification(specification);
    }

    return (
        <div className='grid grid-cols-3 gap-5 py-3'>
            <div className='col-span-2 text-start'>{specification.specificationText}</div>
            {
                loggedUser ?
                    <div className='text-end pr-5'>
                        <FontAwesomeIcon icon={solid('trash')} className="text-black hover:scale-150 duration-500 hover:cursor-pointer hover:text-slate-600" onClick={() => openDeleteSpecificationModal()} />
                    </div> : null
            }
        </div>
    )
}

export default Specification