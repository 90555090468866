import React, { useContext, useState } from 'react'
import { UsersContext } from '../../Contexts/UsersContext'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const LogoutModal = () => {

    const { performLogout } = useContext(UsersContext);

    const history = useHistory();
    
    return (
        <div>
            <input type="checkbox" id="logoutModal" className="modal-toggle" />
            <div className="modal modal-bottom sm:modal-middle">
                <div className="modal-box bg-blue-900">
                    <div>
                        <h3 className="font-bold text-md mb-5 text-white">YOU ARE ABOUT TO LOG OUT</h3>
                        <h3 className="text-md mb-5 text-white">ARE YOU SURE ?</h3>
                    </div>
                    <div className="modal-action">
                        <label htmlFor="logoutModal" className="btn modal-action-btn" onClick={() => performLogout()}>LOGOUT</label>
                        <label htmlFor="logoutModal" className="btn modal-action-btn" onClick={() => history.push('/change')}>CHANGE PASSWORD</label>
                        <label htmlFor="logoutModal" className="btn modal-action-btn">CLOSE</label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LogoutModal