import React, { useContext, useEffect } from 'react'
import UserModal from './UserModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { UsersContext } from '../../Contexts/UsersContext'
import User from './User'
import EditUserModal from './EditUserModal'
import Loading from '../Loading/Loading'
import { LoadingContext } from '../../Contexts/LoadingContext'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import DeleteUserModal from './DeleteUserModal'

const Users = () => {

    const { loggedUser, getUsers, users, errorMessage } = useContext(UsersContext);
    const { loading } = useContext(LoadingContext);

    const history = useHistory();

    const openUsersModal = () => {
        document.getElementById('usersModal').checked = true;
    }

    useEffect(() => {
        if (loggedUser.isAdmin)
            getUsers();
        else
            history.push('/home');
    }, []);

    return (
        loading ? <Loading /> :
            <div className='users-page my-5 mx-5'>
                <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-5'>
                    {
                        errorMessage === '' ? null :
                            <div className='lg:col-start-2 p-5 my-auto text-center font-bold text-red-700'>{errorMessage}</div>
                    }
                    <button type="submit" className="printers-button md:col-start-2 lg:col-start-3" onClick={() => openUsersModal()}>
                        <span className='mr-2'>ADD NEW USER</span>
                        <FontAwesomeIcon icon={solid('user-plus')} />
                    </button>
                </div>
                <div className='mt-5 grid grid-cols-7 bg-gradient-to-r from-blue-700 to-blue-900 gap-5 p-5 text-center'>
                    <div className='text-sm text-vangold font-bold'>USERNAME</div>
                    <div className='text-sm text-vangold font-bold'>ADMIN</div>
                    <div className='text-sm text-vangold font-bold'>CREATE</div>
                    <div className='text-sm text-vangold font-bold'>UPDATE</div>
                    <div className='text-sm text-vangold font-bold'>DELETE</div>
                    <div className='text-sm text-vangold font-bold'>EMAIL</div>
                    <div className='text-sm text-vangold font-bold'>ACTION</div>
                </div>
                {
                    users && users.length > 0 ?
                        users.map((user) => (
                            <User user={user} />
                        ))
                        : <div className='font-bold text-md my-10 col-span-4 text-center'>THERE ARE NO USERS</div>
                }
                <UserModal />
                <EditUserModal />
                <DeleteUserModal />
            </div>
    )
}

export default Users