import React, { useContext, useEffect } from 'react'
import { QuestionsContext } from '../../Contexts/QuestionsContext'
import Question from './Question';
import { UsersContext } from '../../Contexts/UsersContext';
import { useHistory } from 'react-router-dom';

const Questions = () => {

    const { loggedUser } = useContext(UsersContext);
    const { questions, getQuestions } = useContext(QuestionsContext);

    const history = useHistory();

    useEffect(() => {
        if (loggedUser)
            getQuestions();
        else history.push('/');
    }, []);

    return (
        <div className='my-5 mx-5'>
            {
                questions && questions.length > 0 ?
                    questions.map((question) => (
                        <Question question={question} key={question.questionID} />
                    ))
                    : null
            }
        </div>
    )
}

export default Questions