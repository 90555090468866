import React, { useContext, useEffect } from 'react'
import { ParagraphsContext } from '../../../Contexts/ParagraphsContext'
import Paragraph from './Paragraph';
import CreateParagraphModal from './CreateParagraphModal';
import DeleteParagraphModal from './DeleteParagraphModal';
import EditParagraphModal from './EditParagraphModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { UsersContext } from '../../../Contexts/UsersContext';

const Paragraphs = () => {

    const { loggedUser } = useContext(UsersContext);
    const { paragraphs } = useContext(ParagraphsContext);

    const openCreateParagraphModal = () => {

        document.getElementById('createParagraphModal').checked = true;
    }

    return (
        <div>
            {
                loggedUser && loggedUser.canCreate ?
                    <div className="grid md:grid-cols-2">
                        <button type="submit" className="printers-button md:col-start-2 mb-5" onClick={() => openCreateParagraphModal()}>
                            <span className='mr-2'>ADD NEW PARAGRAPH</span>
                            <FontAwesomeIcon icon={solid('plus')} />
                        </button>
                    </div> : null
            }
            <div className='px-5'>
                {
                    paragraphs && paragraphs.length > 0 ?
                        paragraphs.map((paragraph) => (
                            <Paragraph key={paragraph.paragraphID} paragraph={paragraph} />
                        ))
                        : <div className='text-center text-lg font-bold m-auto'>NO CONTENT TO SHOW</div>
                }
                <CreateParagraphModal />
                <DeleteParagraphModal />
                <EditParagraphModal />
            </div>
        </div>

    )
}

export default Paragraphs