import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import Application from '../../Assets/Images/Extensions/Application.png'
import MP4 from '../../Assets/Images/Extensions/MP4.png'
import PDF from '../../Assets/Images/Extensions/PNG.png'
import EXE from '../../Assets/Images/Extensions/EXE.png'
import PNG from '../../Assets/Images/Extensions/PNG.png'
import { DownloadablesContext } from '../../Contexts/DownloadablesContext'
import { useContext } from 'react'

const ProductDownload = ({ downloadable }) => {

    const { download } = useContext(DownloadablesContext);

    const downloadFile = async () => {

        var data = await download(downloadable);
        let myBlob = await data.blob();
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(myBlob);
        a.download = downloadable.filename;
        a.target = "_blank";
        document.body.append(a);
        a.click();
    }

    return (
        <div className='downloadable'>
            <div className="md:grid md:grid-cols-6 mx-auto">
                {
                    downloadable.type === 'Driver' ?
                        <figure><img className='md:w-24 md:h-24 w-16 h-16 mx-auto my-5' src={EXE} alt={downloadable.downloadableName} /></figure>
                        :
                        downloadable.type === 'UserManual' ?
                            <figure><img className='md:w-24 md:h-24 w-16 h-16 mx-auto my-5' src={PNG} alt={downloadable.downloadableName} /></figure>
                            :
                            downloadable.type === 'Introduction' ?
                                <figure><img className='md:w-24 md:h-24 w-16 h-16 mx-auto my-5' src={PDF} alt={downloadable.downloadableName} /></figure>
                                :
                                downloadable.type === 'Setting' ?
                                    <figure><img className='md:w-24 md:h-24 w-16 h-16 mx-auto my-5' src={Application} alt={downloadable.downloadableName} /></figure>
                                    :
                                    download.type === 'Video' ?
                                        <figure><img className='md:w-24 md:h-24 w-16 h-16 mx-auto my-5' src={MP4} alt={downloadable.downloadableName} /></figure>
                                        : null
                }
                <div className='my-auto col-span-4'>
                    <div className='font-bold md:text-start text-center px-5 text-black'>{downloadable.downloadableName}</div>
                    <div className='md:text-start text-center px-5 my-5 text-black'>{downloadable.downloadableDescription}</div>
                </div>
                <div className='my-auto'>
                    <div className="flex relative mb-2">
                        <button type="submit" className="file-button" onClick={() => downloadFile()}>
                            <span className='text-xs mr-2'>DOWNLOAD</span>
                            <FontAwesomeIcon icon={solid('download')} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductDownload