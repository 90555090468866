import React, { useContext, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { ProductsContext } from '../../../Contexts/ProductsContext'

const ProductsModal = () => {

    const { createProduct, product, setProduct, setFile } = useContext(ProductsContext);

    const [uploadedPic, setUploadedPic] = useState('');

    const saveFile = (e) => {

        setFile(e.target.files[0]);
        setProduct({ ...product, filename: e.target.files[0].name });
        setUploadedPic(e.target.files[0].name);
    }

    const openFileInput = () => {

        document.getElementById('fileInput').click();
    }

    return (
        <div>
            <input type="checkbox" id="my-modal-6" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box w-11/12 max-w-5xl bg-blue-900">
                    <h3 className="font-bold text-lg mb-5 text-indigo-100">CREATE NEW PRODUCT</h3>
                    <div className='grid md:grid-cols-2 gap-5'>

                        <input id='fileInput' className="hidden" type="file" placeholder="PRODUCT PICTURE" onChange={(e) => saveFile(e)} />

                        <div className="flex relative">                            
                            <input id="uploadPic" value={uploadedPic ? uploadedPic:''} type="text" className="product-modal-file-input" placeholder="PRODUCT IMAGE" onClick={() => openFileInput()} />
                            <FontAwesomeIcon icon={solid('image')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                        </div>

                        <div className="flex relative">
                            <input className="product-modal-input" type="search" placeholder="PRODUCT NAME" value={product ? product.productName : ''} onChange={(e) => setProduct({ ...product, productName: e.target.value })} />
                            <FontAwesomeIcon icon={solid('print')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                        </div>
                        <div className="flex relative">
                            <input className="product-modal-input" type="search" placeholder="PRODUCT PRICE" value={product ? product.productPrice : ''} onChange={(e) => setProduct({ ...product, productPrice: e.target.value })} />
                            <FontAwesomeIcon icon={solid('dollar-sign')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                        </div>
                        <div className="flex relative">
                            <input className="product-modal-input" type="search" placeholder="PRODUCT SUMMARY" value={product ? product.productSummary : ''} onChange={(e) => setProduct({ ...product, productSummary: e.target.value })} />
                            <FontAwesomeIcon icon={solid('book')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                        </div>
                        <div className="col-span-2">
                            <textarea className='modal-textarea pt-2' type='text' placeholder="PRODUCT DESCRIPTION" value={product ? product.productDescription : ''} onChange={(e) => setProduct({ ...product, productDescription: e.target.value })} />
                        </div>
                    </div>
                    <div className="modal-action">                        
                        <label htmlFor="my-modal-6" className="modal-action-btn" onClick={() => createProduct()}>CREATE</label>
                        <label htmlFor="my-modal-6" className="modal-action-btn">CLOSE</label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductsModal