import React, { useContext } from 'react'
import PNG from '../../../Assets/Images/Extensions/PNG.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { DownloadablesContext } from '../../../Contexts/DownloadablesContext'
import { UsersContext } from '../../../Contexts/UsersContext'

const UserManual = ({ userManual }) => {

    const { loggedUser } = useContext(UsersContext);
    const { setSelectedDownloadable, download } = useContext(DownloadablesContext);

    const openDeleteUserManualModal = () => {

        document.getElementById('deleteUserManualModal').checked = true;
        setSelectedDownloadable(userManual);
    }

    const downloadFile = async () => {

        var data = await download(userManual);
        let myBlob = await data.blob();
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(myBlob);
        a.download = userManual.filename;
        a.target = "_blank";
        document.body.append(a);
        a.click();
    }

    return (
        <div className='downloadable'>
            <div className="grid lg:grid-cols-6">
                <div className='my-5 text-center mx-auto'>
                    <figure><img className='md:w-24 md:h-24 w-16 h-16' src={PNG} alt={userManual.downloadableName} /></figure>
                </div>
                <div className='my-auto lg:col-span-4'>
                    <div className='font-bold lg:text-start text-center px-5 text-blue-900'>{userManual.downloadableName}</div>
                    {
                        userManual.downloadableDescription !== undefined && userManual.downloadableDescription !== '' ?
                            <div className='lg:text-start px-5 text-center text-blue-900'>{userManual.downloadableDescription}</div> : null
                    }
                </div>
                <div className='my-5'>
                    <div className="flex relative mb-2">
                        <button type="submit" className="file-button" onClick={() => downloadFile()}>
                            <span className='text-xs mr-2'>DOWNLOAD</span>
                            <FontAwesomeIcon icon={solid('download')} />
                        </button>
                    </div>
                    {
                        loggedUser && loggedUser.canDelete ?
                            <div className="flex relative">
                                <button type="submit" className="file-button" onClick={() => openDeleteUserManualModal()}>
                                    <span className='text-xs mr-2'>DELETE</span>
                                    <FontAwesomeIcon icon={solid('trash')} />
                                </button>
                            </div> : null
                    }
                </div>
            </div>
        </div>
    )
}

export default UserManual