import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { ProductsContext } from '../../Contexts/ProductsContext'
import Loading from '../Loading/Loading'
import { LoadingContext } from '../../Contexts/LoadingContext'
import AfterSalesServices from './AfterSalesServices/AfterSalesServices'
import { ParagraphsContext } from '../../Contexts/ParagraphsContext'
import { FailuresContext } from '../../Contexts/FailuresContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { QuestionsContext } from '../../Contexts/QuestionsContext'

const Downloads = () => {

    const { question, setQuestion, createQuestion } = useContext(QuestionsContext);
    const { getParagraphs } = useContext(ParagraphsContext);
    const { getFailures } = useContext(FailuresContext);
    const { getProductNames } = useContext(ProductsContext);
    const { loading } = useContext(LoadingContext);

    useEffect(() => {
        getProductNames();
        getParagraphs();
        getFailures();
    }, []);

    return (
        loading ? <Loading /> :
            <div className='relative m-2'>
                <div className=' grid md:grid-cols-4 gap-5 p-5'>
                    <div>
                        <Link to='/drivers'>
                            <div className="download-link-btn">DRIVERS & SDKS</div>
                        </Link>
                        <Link to='/userManuals'>
                            <div className="download-link-btn">
                                USER MANUALS
                            </div>
                        </Link>
                        <Link to='/appSettings'>
                            <div className="download-link-btn">
                                APPLICATIONS & SETTINGS
                            </div>
                        </Link>
                        <Link to='/operationVideos'>
                            <div className="download-link-btn">
                                OPERATION VIDEOS
                            </div>
                        </Link>
                        <Link to='/productIntroductions'>
                            <div className="download-link-btn">
                                PRODUCT INTRODUCTION
                            </div>
                        </Link>
                        <Link to='/downloads'>
                            <div className="download-link-btn">
                                AFTER-SALES SERVICES
                            </div>
                        </Link>
                        <div className='ring-1 ring-black p-3 my-5 rounded-md sticky top-40'>
                            <div className='my-3 mx-2 text-lg font-bold'>
                                SEND YOUR INQUIRY
                            </div>
                            <div className="flex relative my-2">
                                <input id="NAME" type="text" className="product-modal-file-input" placeholder="NAME" value={question ? question.name : ''} onChange={(e) => setQuestion({...question, name:e.target.value})} />
                                <FontAwesomeIcon icon={solid('user')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                            </div>
                            <div className="flex relative my-2">
                                <input id="PHONE" type="text" className="product-modal-file-input" placeholder="PHONE" value={question ? question.phoneNumber : ''} onChange={(e) => setQuestion({...question, phoneNumber:e.target.value})} />
                                <FontAwesomeIcon icon={solid('phone')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                            </div>
                            <div className="flex relative my-2">
                                <input id="EMAIL" type="text" className="product-modal-file-input" placeholder="EMAIL" value={question ? question.email : ''} onChange={(e) => setQuestion({...question, email:e.target.value})} />
                                <FontAwesomeIcon icon={solid('envelope')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                            </div>
                            <div className="flex relative">
                                <textarea className='modal-textarea pt-2' type='text' placeholder="QUESTION" value={question ? question.description : ''} onChange={(e) => setQuestion({...question, description:e.target.value})} />
                                <FontAwesomeIcon icon={solid('stream')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                            </div>
                            <div className="flex relative">
                                <button type="submit" className="printers-button" onClick={() => createQuestion('')}>
                                    <span className='mr-2'>SUBMIT</span>
                                    <FontAwesomeIcon icon={solid('plus')} />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='lg:col-span-3 my-2'>
                        <AfterSalesServices />
                    </div>
                </div>
            </div>
    )
}

export default Downloads