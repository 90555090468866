import React from 'react'

const ContactUs = () => {

    return (
        <div className='app-printers py-10'>
            <div className='font-bold text-white text-4xl'>
                MORE INFORMATION ABOUT THE
            </div>
            <div className='relative font-bold text-yellow-500 text-4xl'>
                PRODUCTS OF OUR COMPANY
            </div>
            <div className='relative text-md my-10 text-white font-bold mx-10'>
                We can offer one-stop services from main board design, materials assembling, production, sales and delivery.
                We aim to be the leader manufacture in printer hardware solutions and services.
            </div>
        </div>
    )
}

export default ContactUs