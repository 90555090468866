import React, { useContext, useEffect } from 'react'
import { ProductsContext } from '../../Contexts/ProductsContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

const OurProducts = () => {

    const { products, getProducts } = useContext(ProductsContext);

    useEffect(() => {
        getProducts();
    }, []);

    return (
        <div className='my-10 px-10 py-5 bg-white'>
            <div className='text-4xl font-bold text-blue-900 text-center mb-5'>OUR PRODUCTS</div>
            <div className='text-md text-center mb-10'>
                Van Gold is a high tech enterprise specialized in thermal receipt printer, Label bar code printer,panel printer and kiosk printer. We now own 3 series, 20 kinds of products, all our Hoin thermal printer products passed CCC, CE, FCC, ROHS, CB, BIS certifications
            </div>
            <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-5'>
                {
                    products && products.length > 0 ?
                        products.map((product) => (
                            <div className='rounded-lg drop-shadow-md overflow-hidden' key={product.productID}>
                                <figure><img className='rounded-lg h-60 w-96 object-contain mb-5' src={product.productImage} alt={product.productName} /></figure>
                                <div className='text-black text-center'>{product.productName}</div>
                            </div>
                        ))
                        : null
                }
            </div>
        </div>
    )
}

export default OurProducts