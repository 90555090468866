import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { SpecificationsContext } from '../../../Contexts/SpecificationsContext'

function CreateSpecificationModal() {

    const { specification, setSpecification, createSpecification } = useContext(SpecificationsContext);

    return (
        <div>
            <input type="checkbox" id="createSpecificationModal" className="modal-toggle" />
            <div className="modal modal-bottom sm:modal-middle">
                <div className="modal-box bg-blue-900">
                    <h3 className="font-bold text-lg mb-5 text-indigo-100">ADD NEW SPECIFICATION</h3>
                    <div className="flex relative">
                        <input className="product-modal-input" type="search" placeholder="SPECIFICATION DESCRIPTION" value={specification ? specification.specificationText : ''} onChange={(e) => setSpecification({ ...specification, specificationText: e.target.value })} />
                        <FontAwesomeIcon icon={solid('dollar-sign')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                    </div>
                    <div className="modal-action">
                        <label htmlFor="createSpecificationModal" className="modal-action-btn" onClick={() => createSpecification()}>CREATE</label>
                        <label htmlFor="createSpecificationModal" className="modal-action-btn">CLOSE</label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateSpecificationModal