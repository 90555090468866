import React, { useContext } from 'react'
import { FailuresContext } from '../../../Contexts/FailuresContext'
import CreateFailureModal from './CreateFailureModal';
import DeleteFailureModal from './DeleteFailureModal';
import EditFailureModal from './EditFailureModal';
import Failure from './Failure';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { UsersContext } from '../../../Contexts/UsersContext';

const Failures = () => {

    const { loggedUser } = useContext(UsersContext);
    const { failures } = useContext(FailuresContext);

    const styleUser = 'grid grid-cols-4 mt-5 gap-5 p-2 bg-gradient-to-r text-sm rounded-t-md drop-shadow-md from-blue-900 to-blue-700 text-vangold ring-1 ring-blue-900'
    const style = 'grid grid-cols-3 mt-5 gap-5 p-2 bg-gradient-to-r text-sm rounded-t-md drop-shadow-md from-blue-900 to-blue-700 text-vangold ring-1 ring-blue-900'

    const openCreateFailureModal = () => {

        document.getElementById('createFailureModal').checked = true;
    }

    return (
        <div className='my-5'>
            {
                loggedUser && loggedUser.canCreate ?
                    <div className="grid md:grid-cols-2">
                        <button type="submit" className="printers-button md:col-start-2" onClick={() => openCreateFailureModal()}>
                            <span className='mr-2'>ADD NEW FAILURE</span>
                            <FontAwesomeIcon icon={solid('plus')} />
                        </button>
                    </div> : null
            }
            <div className={loggedUser ? styleUser : style}>
                <div className='font-bold text-center'>FAILURE PHENOMENON</div>
                <div className='font-bold text-center'>FAILURE CAUSE</div>
                <div className='font-bold text-center'>SOLUTION</div>
                {
                    loggedUser ?
                        <div className='font-bold text-center'>ACTION</div> : null
                }
            </div>
            {
                failures && failures.length > 0 ?
                    failures.map((failure) => (
                        <Failure key={failure.failureID} failure={failure} />
                    ))
                    : <div className='text-center text-lg font-bold m-auto'>NO FAILURES TO SHOW IN THE TABLE</div>
            }
            <CreateFailureModal />
            <DeleteFailureModal />
            <EditFailureModal />
        </div>
    )
}

export default Failures