import React, { useContext } from 'react'
import { ProductsContext } from '../../../Contexts/ProductsContext'

const HideShowProductModal = () => {
    
    const { selectedProduct, hideShowProduct } = useContext(ProductsContext);

    return (
        <div>
            <input type="checkbox" id="hideShowProductModal" className="modal-toggle" />
            <div className="modal modal-bottom sm:modal-middle">
                <div className="modal-box bg-blue-900">
                    {
                        selectedProduct && selectedProduct.isHidden ?                                                 
                        <div>
                            <h3 className="font-bold text-md mb-5 text-white">SHOWING THIS PRODUCT MEANS CUSTOMERS WILL BE ABLE TO SEE IT IN THE PRODUCTS LIST</h3>                    
                            <h3 className="text-md mb-5 text-white">ARE YOU SURE YOU WANT TO DO THIS ?</h3>                    
                        </div>
                        :
                        <div>
                            <h3 className="font-bold text-md mb-5 text-white">HIDING THIS PRODUCT MEANS CUSTOMERS WILL NOT BE ABLE TO SEE IT IN THE PRODUCTS LIST</h3>                    
                            <h3 className="text-md mb-5 text-white">ARE YOU SURE YOU WANT TO DO THIS ?</h3>                    
                        </div>
                    }                                        
                    <div className="modal-action">
                        <label htmlFor="hideShowProductModal" className="modal-action-btn" onClick={() => hideShowProduct(selectedProduct.productID)}>CONFIRM</label>
                        <label htmlFor="hideShowProductModal" className="modal-action-btn">CLOSE</label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HideShowProductModal