import React, { useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { UsersContext } from '../../Contexts/UsersContext'

const LoginModal = () => {

    const { user, setUser, performLogin, checkLoggedUser, setForgotRequest, forgotRequest, initiateForgotPassword, errorMessage, setErrorMessage } = useContext(UsersContext);

    const [forgotPassword, setForgotPassword] = useState(false);

    useEffect(() => {
        checkLoggedUser();
    }, []);

    return (
        <div>
            <input type="checkbox" id="loginModal" className="modal-toggle" />
            <div className="modal modal-bottom sm:modal-middle">
                <div className="modal-box bg-blue-900">
                    {
                        forgotPassword ?
                            <h3 className="font-bold text-lg mb-5 text-indigo-100">FORGOT PASSWORD</h3> :
                            <h3 className="font-bold text-lg mb-5 text-indigo-100">LOGIN</h3>
                    }
                    {
                        forgotPassword ?
                            <div>
                                <div className="flex relative">
                                    <input className="modal-input" type="search" placeholder="USERNAME" value={forgotRequest ? forgotRequest.username : ''} onChange={(e) => setForgotRequest({ ...forgotRequest, username: e.target.value })} />
                                    <FontAwesomeIcon icon={solid('user')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                                </div>
                                <div className="flex relative">
                                    <input className="modal-input" type="search" placeholder="EMAIL" value={forgotRequest ? forgotRequest.email : ''} onChange={(e) => setForgotRequest({ ...forgotRequest, email: e.target.value })} />
                                    <FontAwesomeIcon icon={solid('envelope')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                                </div>
                            </div>
                            :
                            <div>
                                <div className="flex relative">
                                    <input className="modal-input" type="search" placeholder="USERNAME" value={user ? user.username : ''} onChange={(e) => setUser({ ...user, username: e.target.value })} />
                                    <FontAwesomeIcon icon={solid('user')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                                </div>
                                <div className="flex relative">
                                    <input className="modal-input" type="password" placeholder="PASSWORD" value={user ? user.password : ''} onChange={(e) => setUser({ ...user, password: e.target.value })} />
                                    <FontAwesomeIcon icon={solid('lock')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                                </div>
                            </div>
                    }
                    {
                        !forgotPassword ?
                            <div className="modal-action">
                                <div htmlFor="loginModal" className="btn modal-action-btn" onClick={() => performLogin()}>LOGIN</div>
                                <div className="btn modal-action-btn" onClick={() => { setForgotPassword(true); setErrorMessage('')}}>FORGOT PASSWORD</div>
                                <label htmlFor="loginModal" className="btn modal-action-btn">CLOSE</label>
                            </div>
                            :
                            <div className="modal-action">
                                <div htmlFor="loginModal" className="btn modal-action-btn" onClick={() => initiateForgotPassword()}>CHANGE PASSWORD</div>
                                <div htmlFor="loginModal" className="btn modal-action-btn" onClick={() => { setForgotPassword(false); setErrorMessage('')}}>BACK TO LOGIN</div>
                            </div>
                    }
                    {
                        errorMessage !== '' ? 
                        <div className='lg:col-start-2 p-5 my-auto text-center font-bold text-vangold'>{errorMessage}</div> : null
                    }
                </div>
            </div>
        </div>
    )
}

export default LoginModal