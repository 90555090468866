import React from 'react'
import Paragraphs from '../Paragraphs/Paragraphs'
import Failures from '../Failures/Failures'

const AfterSalesServices = () => {

    return (
        <div>
            <Paragraphs />
            <Failures />
        </div>
    )
}

export default AfterSalesServices