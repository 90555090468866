import React, { useContext } from 'react'
import { ParagraphsContext } from '../../../Contexts/ParagraphsContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

const EditParagraphModal = () => {

    const { selectedParagraph, setSelectedParagraph, updateParagraph } = useContext(ParagraphsContext);

    return (
        <div>
            <input type="checkbox" id="editParagraphModal" className="modal-toggle" />
            <div className="modal modal-bottom sm:modal-middle">
                <div className="modal-box bg-blue-900">
                    <h3 className="font-bold text-lg mb-5 text-indigo-100">EDIT PARAGRAPH</h3>
                    <div className="flex relative">
                        <input className="modal-input" type="search" placeholder="PARAGRAPH HEADER" value={selectedParagraph ? selectedParagraph.header : ''} onChange={(e) => setSelectedParagraph({ ...selectedParagraph, header: e.target.value })} />
                        <FontAwesomeIcon icon={solid('grip-vertical')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                    </div>
                    <div className="flex relative">
                        <textarea className='modal-textarea pt-2' type='text' placeholder="PARAGRAPH TEXT" value={selectedParagraph ? selectedParagraph.paragraphText : ''} onChange={(e) => setSelectedParagraph({ ...selectedParagraph, paragraphText: e.target.value })} />
                        <FontAwesomeIcon icon={solid('stream')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                    </div>
                    <div className="modal-action">
                        <label htmlFor="editParagraphModal" className="modal-action-btn" onClick={() => updateParagraph()}>EDIT</label>
                        <label htmlFor="editParagraphModal" className="modal-action-btn">CLOSE</label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditParagraphModal