import React from 'react'

const ProductVideo = ({ download }) => {

    return (

        <div className='downloadable'>
            <div className='text-md text-center font-bold mb-3'>{download.downloadableName}</div>
            <div className='text-md text-center mb-3'>{download.downloadableDescription}</div>
            <video class="flex h-96 max-w-sm w-full bg-white shadow-md rounded-lg overflow-hidden mx-auto" controls>
                <source src={require('../../Assets/Files/Video/WhatsApp Video 2023-04-10 at 20.59.00.mp4')} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    )
}

export default ProductVideo