import React, { useContext } from 'react'
import { UsersContext } from '../../../Contexts/UsersContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { ParagraphsContext } from '../../../Contexts/ParagraphsContext'

const Paragraph = ({ paragraph }) => {

    const { loggedUser } = useContext(UsersContext);
    const { setSelectedParagraph } = useContext(ParagraphsContext);

    const openDeleteParagraphModal = (e) => {

        e.preventDefault();
        document.getElementById('deleteParagraphModal').checked = true;
        setSelectedParagraph(paragraph);
    }

    const openEditParagraphModal = (e) => {

        e.preventDefault();
        document.getElementById('editParagraphModal').checked = true;
        setSelectedParagraph(paragraph);
    }

    return (
        <div>
            <div className="flex gap-5 mb-5">
                <div className='text-lg font-bold flex-5'>
                    {paragraph.header}
                </div>
                <div className='flex gap-5 flex-1 text-end justify-end justify-items-end'>
                    {
                        loggedUser && loggedUser.canDelete ?
                            <FontAwesomeIcon icon={solid('trash')} className="text-black hover:scale-150 duration-200 hover:cursor-pointer hover:text-slate-600" onClick={(e) => openDeleteParagraphModal(e)} /> : null
                    }
                    {
                        loggedUser && loggedUser.canUpdate ?
                            <FontAwesomeIcon icon={solid('pen-to-square')} className="text-black hover:scale-150 duration-200 hover:cursor-pointer hover:text-slate-600" onClick={(e) => openEditParagraphModal(e)} /> : null
                    }
                </div>
            </div>
            <div className='text-sm text-justify mb-5'>{paragraph.paragraphText}</div>
        </div>
    )
}

export default Paragraph