import React from 'react'

const OEMODM = () => {

    return (
        <div className='py-5 grid lg:grid-cols-2 gap-5'>
            <figure><img className='lg:rounded-tr-xl lg:rounded-br-xl drop-shadow-md' src={require('../../Assets/Images/Home/OEMODM.png')} alt='Picture'/></figure>
            <div className='text-justify mx-10 my-auto'>
                <div className='text-blue-900 font-bold text-4xl text-center'>OEM & ODM SERVICE</div>
                <div className='my-5'>
                    Van Gold, as a professional thermal printer manufacturer, we strictly control raw materials and component channels, seriously audit on supplier’s qualification, Continuously improve R&D and production of printer machine industry chain. We can provide OEM and ODM support. All our engineers with more than 10 years experience in POS printer field, we will continue to provide you the most professional customized sevices and printers.
                </div>
                <div className='my-5'>
                    <b>Inquiry:</b> Clients tell us about the printer interface, printer paper width, product using purpose and whether need customized logo or Hoin brand, quantities etc., we will recommend the best suitable model for you.
                </div>
                <div className="my-5">
                    <b>Design:</b> The design including the printer new modeling outlook design, main board design, logo design, paper box design etc.; Customized design is supported for meeting our client’s need.
                </div>
                <div className="my-5">
                    <b>Quality Management:</b> In Order to make sure high quality products, we strictly follow the effective & efficient Quality Management System
                </div>
                {/* <div className="my-7 w-48 mx-auto">
                    <button type="submit" className="home-button">
                        <span className='mr-2'>READ MORE</span>
                    </button>
                </div> */}
            </div>
        </div>
    )
}

export default OEMODM