import React, { useContext, useEffect } from 'react'
import { PhotosContext } from '../../Contexts/PhotosContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { UsersContext } from '../../Contexts/UsersContext';

const Photos = () => {

    const { loggedUser } = useContext(UsersContext);
    const { photos, getPhotos, setDisplayedPhoto, deletePhoto } = useContext(PhotosContext);

    const openPhotoGalleryModal = (photo) => {

        setDisplayedPhoto(photo);
        document.getElementById('photoGalleryModal').checked = true;
    }

    useEffect(() => {
        getPhotos();
    }, []);

    return (
        <div className='grid grid-cols-4 gap-5 py-2'>
            {
                photos && photos.length > 0 ?
                    photos.map((photo) => (
                        <div>
                            <img className='ring-1 ring-slate-500 h-16 w-20 object-cover rounded-md drop-shadow-md hover:cursor-zoom-in' src={photo.photoPath} alt={photo.photoPath} onClick={() => openPhotoGalleryModal(photo.photoPath)} />
                            {
                                loggedUser && loggedUser.canDelete ?
                                    <div className='text-center'>
                                        <FontAwesomeIcon icon={solid('trash')} className="text-black my-5 w-4 h-4 hover:scale-150 duration-300 hover:text-slate-600 hover:cursor-pointer" onClick={() => deletePhoto(photo)} />
                                    </div> : null
                            }
                        </div>
                    ))
                    : null
            }
        </div>
    )
}

export default Photos