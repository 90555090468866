import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { QuestionsContext } from '../../Contexts/QuestionsContext';

const Contact = () => {

    const { question, setQuestion, createQuestion } = useContext(QuestionsContext);

    return (
        <div className='mx-10 my-5 text-center'>
            <div className='font-bold text-4xl my-5 text-blue-900'>CONTACT INFORMATION</div>
            <div className='text-md'>With an outstanding design concept and a global vision, we continue to innovate, improve production technology. We strive to make Van Gold an international OEM/ODM preferred supplier.</div>
            <div className='grid md:grid-cols-3 gap-3 my-10'>
                <div>
                    <FontAwesomeIcon icon={solid('phone')} className="text-black w-10 h-10" />
                    <div className='font-bold my-2'>PHONE</div>
                    <div className='my-2'>07701603747</div>
                </div>
                <div>
                    <FontAwesomeIcon icon={solid('envelope')} className="text-black w-10 h-10" />
                    <div className='font-bold my-2'>EMAIL</div>
                    <div className='my-2'>van.gold@gmail.com</div>
                </div>
                <div>
                    <FontAwesomeIcon icon={solid('map-marker-alt')} className="text-black w-10 h-10" />
                    <div className='font-bold my-2'>ADDRESS</div>
                    <div className='my-2'></div>
                </div>
            </div>
            <div className='bg-vangold p-5 my-10 rounded-lg drop-shadow-md'>
                <div className="font-bold text-2xl text-blue-900 mb-5">GET IN TOUCH WITH US</div>
                <div className="font-bold text-sm mb-5 text-white">We rely on research and development strength, personalized solutions, stable product quality, scientific management,rich marketing experience and perfect market channels. If you have a project then please get in touch and we can discuss your requirements and needs.</div>
                <div className='md:grid md:grid-cols-3 gap-5'>
                    <div>
                        <div className="flex relative">
                            <input className="modal-input" type="search" placeholder="NAME" value={question ? question.name : ''} onChange={(e) => setQuestion({ ...question, name: e.target.value })} />
                            <FontAwesomeIcon icon={solid('user')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                        </div>
                    </div>
                    <div>
                        <div className="flex relative">
                            <input className="modal-input" type="search" placeholder="PHONE NUMBER" value={question ? question.phoneNumber : ''} onChange={(e) => setQuestion({ ...question, phoneNumber: e.target.value })} />
                            <FontAwesomeIcon icon={solid('phone')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                        </div>
                    </div>
                    <div>
                        <div className="flex relative">
                            <input className="modal-input" type="search" placeholder="EMAIL ADDRESS" value={question ? question.email : ''} onChange={(e) => setQuestion({ ...question, email: e.target.value })} />
                            <FontAwesomeIcon icon={solid('envelope')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                        </div>
                    </div>
                    <textarea className='modal-textarea col-span-3 p-2' type='text' placeholder="INQUIRY" value={question ? question.description : ''} onChange={(e) => setQuestion({ ...question, description: e.target.value })} />
                </div>
                <button type="submit" className="home-button" onClick={() => createQuestion('')} >
                    <span className='mr-2'>SEND INQUIRY</span>
                    <FontAwesomeIcon icon={solid('share')} className="text-white" />
                </button>
            </div>
        </div>
    )
}

export default Contact