import React, { useContext, useEffect } from 'react'
import Services from './Services'
import { UsersContext } from '../../Contexts/UsersContext'
import OEMODM from './OEMODM'
import OurProducts from './OurProducts'
import ContactUs from './ContactUs'
import AboutUs from './AboutUs'
import SendToUs from './SendToUs'

const Home = () => {

    const { checkLoggedUser } = useContext(UsersContext);

    useEffect(() => {
        checkLoggedUser();
    }, []);

    return (
        <div className='bg-slate-100'>            
            <OEMODM />
            <Services />
            <OurProducts />
            <ContactUs />
            <AboutUs />
            <SendToUs />
        </div>
    )
}

export default Home