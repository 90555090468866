import React, { useContext, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { PhotosContext } from '../../../Contexts/PhotosContext';

function CreatePhotoModal() {

    const { createPhoto } = useContext(PhotosContext);

    const [uploadPhotos, setUploadPhotos] = useState([]);

    return (
        <div>
            <input type="checkbox" id="createPhotoModal" className="modal-toggle" />
            <div className="modal modal-bottom sm:modal-middle">
                <div className="modal-box bg-blue-900">
                    <div className="flex relative col-start-2 m-2">
                        <input className="product-modal-input" type="file" placeholder="PHOTOS" multiple onChange={(e) => setUploadPhotos(e.target.files)} />
                        <FontAwesomeIcon icon={solid('print')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                    </div>
                    <div className="modal-action">
                        <label htmlFor="createPhotoModal" className="btn modal-action-btn" onClick={() => createPhoto(uploadPhotos)}>CREATE</label>
                        <label htmlFor="createPhotoModal" className="btn modal-action-btn">CLOSE</label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreatePhotoModal