import React, { useContext, useEffect, useState } from 'react'
import { LoadingContext } from '../../Contexts/LoadingContext';
import { UsersContext } from '../../Contexts/UsersContext';
import Loading from '../Loading/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ChangePassword = () => {
    
    const { loggedUser, errorMessage, performChangePassword } = useContext(UsersContext);
    const { loading } = useContext(LoadingContext);

    const history = useHistory();

    const [confirmError, setConfirmError] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const checkPasswords = () => {

        if (confirmPassword === password) {
            setConfirmError(false);
            performChangePassword(password);
        }

        else
            setConfirmError(true);
    }

    useEffect(() => {
        if (!loggedUser)
            history.push('/home'); 
    })

    return (
        loading ? <Loading /> :
        <div className="change-password grid lg:grid-cols-3 gap-5 p-2">
            {
                errorMessage === '' ?
                    <div className='lg:col-start-2 p-5 my-auto'>
                        <div className='text-xl font-bold text-center mb-5'>CHANGE PASSWORD</div>
                        <div className="flex relative">
                            <input className="modal-input" type="password" placeholder="PASSWORD" value={password ? password : ''} onChange={(e) => setPassword(e.target.value)} />
                            <FontAwesomeIcon icon={solid('lock')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                        </div>
                        <div className="flex relative">
                            <input className="modal-input" type="password" placeholder="CONFIRM PASSWORD" value={confirmPassword ? confirmPassword : ''} onChange={(e) => { setConfirmPassword(e.target.value) }} />
                            <FontAwesomeIcon icon={solid('lock')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                        </div>
                        <div className="flex relative">
                            <button type="submit" className="printers-button" onClick={() => checkPasswords()}>
                                <span className='mr-2'>CONFIRM</span>
                                <FontAwesomeIcon icon={solid('plus')} />
                            </button>
                        </div>
                        {
                            confirmError ?
                                <div className='lg:col-start-2 p-5 my-auto text-center font-bold text-red-700'>PASSWORD AND CONFIRM DO NOT MATCH</div> : null
                        }
                    </div> : <div className='lg:col-start-2 p-5 my-auto text-center font-bold text-red-700'>{errorMessage}</div>
            }
        </div>
    )
}

export default ChangePassword