import { useContext } from "react";
import { createContext, useState } from "react";
import configData from '../config.json'
import { LoadingContext } from "./LoadingContext";

export const DownloadablesContext = createContext();

const DownloadablesContextProvider = (props) => {

    const apiPath = configData.apiPath;
    const { setLoading, setLoadingMessage } = useContext(LoadingContext);

    const [downloadable, setDownloadable] = useState();
    const [downloadables, setDownloadables] = useState([]);
    const [selectedDownloadable, setSelectedDownloadable] = useState();
    const [file, setFile] = useState();

    const getDownloadables = async (type) => {

        setLoading(true);
        setLoadingMessage("GETTING FILES ...");
        var resp = await fetch(apiPath + 'downloadables/get?type=' + type);
        if (resp.ok) { var data = await resp.json(); setDownloadables(data); setLoading(false); }
    }

    const searchDownloadables = async (search, type) => {

        if (search !== '') {
            var resp = await fetch(apiPath + 'downloadables/search?search=' + search + '&type=' + type);
            if (resp.ok) { var data = await resp.json(); setDownloadables(data); }
        }
        else
            getDownloadables();
    }

    const createDownloadable = async (type) => {

        setLoading(true);
        setLoadingMessage("UPLOADING FILE ...");
        downloadable.type = type;

        const formData = new FormData();
        formData.append("type", downloadable.type);
        formData.append("downloadableName", downloadable.downloadableName);
        if (downloadable.downloadableDescription !== undefined)
            formData.append("downloadableDescription", downloadable.downloadableDescription);
        if (downloadable.productID !== undefined)
            formData.append("productID", downloadable.productID);
        formData.append("filename", downloadable.filename);
        formData.append("file", file);

        var resp = await fetch(apiPath + 'downloadables/create', {
            method: 'POST', mode: 'cors', cache: 'no-cache', credentials: 'same-origin',
            body: formData
        });

        if (resp.ok) { getDownloadables(type); setDownloadable(); setLoading(false); }
    }

    const deleteDownloadable = async (download) => {

        setLoading(true);
        setLoadingMessage("DELETING FILE ...");
        var resp = await fetch(apiPath + 'downloadables/delete', {
            method: 'DELETE', mode: 'cors', cache: 'no-cache', credentials: 'same-origin',
            headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(download)
        });

        if (resp.ok) { getDownloadables(download.type); setLoading(false); }
    }

    const download = async (download) => {

        setLoading(true);
        setLoadingMessage("DOWNLOADING FILE ...");

        var response = await fetch(apiPath + 'downloadables/download', {
            method: 'POST', mode: 'cors',
            credentials: 'same-origin', headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(download)
        });

        setLoading(false);
        return response;
    }

    return (
        <DownloadablesContext.Provider value={{
            downloadable, setDownloadable,
            downloadables, setDownloadables,
            selectedDownloadable, setSelectedDownloadable,
            file, setFile,
            getDownloadables, createDownloadable,
            deleteDownloadable, searchDownloadables,
            download
        }}>
            {props.children}
        </DownloadablesContext.Provider>
    )
}

export default DownloadablesContextProvider;