import React, { useContext, useEffect } from 'react'
import { SpecificationsContext } from '../../Contexts/SpecificationsContext'
import Specification from './Specification'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { UsersContext } from '../../Contexts/UsersContext'

const SpecificationRecords = () => {

    const { loggedUser } = useContext(UsersContext);
    const { specifications, getSpecifications } = useContext(SpecificationsContext);

    const openCreateSpecificationModal = () => {

        document.getElementById('createSpecificationModal').checked = true;
    }

    useEffect(() => {
        getSpecifications();
    }, []);

    return (
        <div className='mt-5'>
            <div className='grid grid-cols-2 border-black border-b-2 py-5'>
                <div className='text-start text-lg font-bold text-black'>SPECIFICATIONS</div>
                {
                    loggedUser && loggedUser.canCreate ?
                        <div className='text-end pr-5' onClick={() => openCreateSpecificationModal()}>
                            <FontAwesomeIcon icon={solid('plus')} className="text-black hover:scale-150 duration-500 hover:cursor-pointer hover:text-slate-600" />
                        </div> : null
                }
            </div>
            {
                specifications && specifications.length > 0 ?
                    specifications.map((specification) => (
                        <Specification specification={specification} key={specification.specificationID} />
                    ))
                    : <div className='font-bold py-3'>NO SPECIFICATIONS</div>
            }
        </div>
    )
}

export default SpecificationRecords