import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { UsersContext } from '../../Contexts/UsersContext'

const User = ({ user }) => {

    const { deleteUser, setSelectedUser } = useContext(UsersContext);

    const handleDelete = (e) => {

        e.preventDefault();
        document.getElementById('deleteUserModal').checked = true;
        setSelectedUser(user);        
    }
    
    const openEditModal = (e) => {

        e.preventDefault();
        document.getElementById('editUserModal').checked = true;
        setSelectedUser(user);        
    }

    return (
        <div className='grid grid-cols-7 gap-5 bg-vangold hover:bg-vangold-200 p-5 text-center'>
            <div className='text-sm text-blue-900'>{user.username}</div>
            <div className='text-sm text-blue-900'>
                {
                    user.isAdmin ?
                        <FontAwesomeIcon icon={solid('check')} className="text-blue-900 w-6 h-6" />
                        :
                        <FontAwesomeIcon icon={solid('times')} className="text-blue-900 w-6 h-6" />
                }
            </div>
            <div className='text-sm text-blue-900'>
                {
                    user.canCreate ?
                        <FontAwesomeIcon icon={solid('check')} className="text-blue-900 w-6 h-6" />
                        :
                        <FontAwesomeIcon icon={solid('times')} className="text-blue-900 w-6 h-6" />
                }
            </div>
            <div className='text-sm text-blue-900'>
                {
                    user.canUpdate ?
                        <FontAwesomeIcon icon={solid('check')} className="text-blue-900 w-6 h-6" />
                        :
                        <FontAwesomeIcon icon={solid('times')} className="text-blue-900 w-6 h-6" />
                }
            </div>
            <div className='text-sm text-blue-900'>
                {
                    user.canDelete ?
                        <FontAwesomeIcon icon={solid('check')} className="text-blue-900 w-6 h-6" />
                        :
                        <FontAwesomeIcon icon={solid('times')} className="text-blue-900 w-6 h-6" />
                }
            </div>
            <div className='text-sm text-blue-900'>{user.email}</div>
            <div className='text-sm text-blue-900 flex justify-center'>
                <FontAwesomeIcon icon={solid('pen-to-square')} className="mx-5 text-blue-900 w-6 h-6 hover:scale-125 hover:duration-200 hover:cursor-pointer" onClick={(e) => openEditModal(e)} />
                <FontAwesomeIcon icon={solid('trash')} className="mx-5 text-blue-900 w-6 h-6 hover:scale-125 hover:duration-200 hover:cursor-pointer" onClick={(e) => handleDelete(e)} />
            </div>
        </div>
    )
}

export default User