import React, { useContext } from 'react'
import { DownloadablesContext } from '../../../../Contexts/DownloadablesContext';

const DeleteOperationVideoModal = () => {

    const { selectedDownloadable, deleteDownloadable } = useContext(DownloadablesContext);

    return (
        <div>
            <input type="checkbox" id="deleteOperationVideoModal" className="modal-toggle" />
            <div className="modal modal-bottom sm:modal-middle">
                <div className="modal-box bg-blue-900">
                    <div>
                        <h3 className="font-bold text-md mb-5 text-white">THIS ACTION WILL PERMENANTLY DELETE THIS OPERATION VIDEO AND IT WILL NO LONGER APPEAR IN THE VIDEOS LIST</h3>
                        <h3 className="text-md mb-5 text-white">ARE YOU SURE YOU WANT TO DO THIS ?</h3>
                    </div>
                    <div className="modal-action">
                        <label htmlFor="deleteOperationVideoModal" className="btn modal-action-btn" onClick={() => deleteDownloadable(selectedDownloadable)}>CONFIRM</label>
                        <label htmlFor="deleteOperationVideoModal" className="btn modal-action-btn">CLOSE</label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteOperationVideoModal