import { createContext, useContext, useState } from "react";
import configData from '../config.json'
import { LoadingContext } from "./LoadingContext";

export const QuestionsContext = createContext();

const QuestionsContextProvider = (props) => {

    const apiPath = configData.apiPath;

    const { setLoading } = useContext(LoadingContext);

    const [count, setCount] = useState(0);
    const [question, setQuestion] = useState();
    const [questions, setQuestions] = useState([]);    
    const [selectedQuestion, setSelectedQuestion] = useState();

    const getQuestions = async () => {

        setLoading(true);        
        var resp = await fetch(apiPath + 'questions/get');        
        if (resp.ok) { var data = await resp.json(); setQuestions(data); setLoading(false); }
    }

    const getQuestionsCount = async () => {

        var resp = await fetch(apiPath + 'questions/count');        
        if (resp.ok) { var data = await resp.json(); setCount(data); }
    }

    const createQuestion = async (product) => {
        
        setLoading(true);        
        question.productName = product;
        
        var resp = await fetch(apiPath + 'questions/create', {
            method: 'POST', mode: 'cors', cache: 'no-cache', credentials: 'same-origin',
            headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(question)
        });

        if (resp.ok) { getQuestions(); setQuestion(null); setLoading(false); } 
    }

    const updateQuestion = async (questionID) => {
        
        var resp = await fetch(apiPath + 'questions/update?questionID=' + questionID, {
            method: 'PATCH', mode: 'cors', cache: 'no-cache', credentials: 'same-origin',
            headers: { 'Content-Type': 'application/json' }
        });

        if (resp.ok) { getQuestions(); } 
    }

    return(
        <QuestionsContext.Provider value={{
            count, setCount,
            question, setQuestion,
            questions, setQuestions,
            selectedQuestion, setSelectedQuestion,
            getQuestions, createQuestion,
            getQuestionsCount, updateQuestion      
        }}>
            {props.children}
        </QuestionsContext.Provider>
    )
}

export default QuestionsContextProvider;