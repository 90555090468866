import { createContext, useContext, useState } from "react";
import { ProductsContext } from "./ProductsContext";
import configData from '../config.json'
import { LoadingContext } from "./LoadingContext";

export const PhotosContext = createContext();

const PhotosContextProvider = (props) => {

    const apiPath = configData.apiPath;
    const { setLoading, setLoadingMessage } = useContext(LoadingContext);

    const { selectedProduct } = useContext(ProductsContext);

    const [photos, setPhotos] = useState([]);
    const [selectedPhoto, setSelectedPhoto] = useState();
    const [displayedPhoto, setDisplayedPhoto] = useState('');
    const [files, setFiles] = useState([]);

    const getPhotos = async () => {

        console.log('getting photos');
        var resp = await fetch(apiPath + 'productPhotos/get?productID=' + selectedProduct.productID);
        if (resp.ok) { var data = await resp.json(); setPhotos(data); }
    }

    const createPhoto = async (files) => {

        var resp = null;
        setLoading(true);
        setLoadingMessage("UPLOADING PHOTO ...");        

        for (const photo of files) {

            const formData = new FormData();
            formData.append("file", photo);
            formData.append("productID", selectedProduct.productID);

            resp = await fetch(apiPath + 'productPhotos/create', {
                method: 'POST', mode: 'cors', cache: 'no-cache', credentials: 'same-origin',
                body: formData
            });
            
            if (resp.ok) { getPhotos(); }
        }                      

        setLoading(false);
    }

    const deletePhoto = async (deletedPhoto) => {

        setLoading(true);
        setLoadingMessage("DELETING PHOTO ...");     

        var resp = await fetch(apiPath + 'productPhotos/delete', {
            method: 'DELETE', mode: 'cors', cache: 'no-cache', credentials: 'same-origin',
            headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(deletedPhoto)
        });

        if (resp.ok) { getPhotos(); setLoading(false); }
    }

    return (
        <PhotosContext.Provider value={{
            photos, setPhotos,
            selectedPhoto, setSelectedPhoto,
            files, setFiles,
            displayedPhoto, setDisplayedPhoto,
            getPhotos, createPhoto,
            deletePhoto
        }}>
            {props.children}
        </PhotosContext.Provider>
    )
}

export default PhotosContextProvider;