import { createContext, useContext, useState } from "react";
import { PouchDBContext } from "./PouchDBContext";
import { useHistory } from "react-router-dom";
import configData from '../config.json'
import { LoadingContext } from "./LoadingContext";

export const UsersContext = createContext();

const UsersContextProvider = (props) => {

    const apiPath = configData.apiPath;
    const forgotPage = configData.forgotPage;
    const history = useHistory();

    const { setLoading, setLoadingMessage } = useContext(LoadingContext);
    const { setPersistedUser, getPersistedUser, removePersistedUser } = useContext(PouchDBContext);

    const [user, setUser] = useState();
    const [users, setUsers] = useState([]);
    const [newUser, setNewUser] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const [loggedUser, setLoggedUser] = useState(null);
    const [forgotRequest, setForgotRequest] = useState();
    const [errorMessage, setErrorMessage] = useState('');

    const checkLoggedUser = async () => {

        if (!loggedUser) {

            getPersistedUser().then((persistedUser) => {
                setLoggedUser(persistedUser);
            }).catch(err => setLoggedUser(null));
        }
    }

    const getUsers = async () => {

        setLoading(true);

        var resp = await fetch(apiPath + 'users/getUsers');
        if (resp.ok) { var data = await resp.json(); setUsers(data); setLoading(false)}
    }

    const performLogin = async () => {

        var resp = await fetch(apiPath + 'users/login', {
            method: 'POST', mode: 'cors', cache: 'no-cache', credentials: 'same-origin',
            headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(user)
        });

        if (resp.ok) { var data = await resp.json(); setLoggedUser(data); setPersistedUser(data); document.getElementById('loginModal').checked = false; setErrorMessage('');}

        else if (resp.status === 400 || resp.status === 404) {
            var data = await resp.json();
            setErrorMessage(data.message);            
        }
    }

    const performLogout = async () => {

        removePersistedUser(loggedUser).then(() => {
            setLoggedUser(null);
            history.push('/home');
        }).catch(err => setLoggedUser(null), history.push('/home'));
    }

    const createUser = async () => {

        setLoading(true);

        var resp = await fetch(apiPath + 'users/createUser', {
            method: 'POST', mode: 'cors', cache: 'no-cache', credentials: 'same-origin',
            headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(newUser)
        });

        if (resp.ok) { setNewUser(); getUsers(); setLoading(false); setErrorMessage('')}

        else if (resp.status === 400 || resp.status === 404) {
            var data = await resp.json();
            setErrorMessage(data.message);
            setLoading(false);
        }
    }

    const deleteUser = async () => {

        setLoading(true);

        var resp = await fetch(apiPath + 'users/delete', {
            method: 'DELETE', mode: 'cors', cache: 'no-cache', credentials: 'same-origin',
            headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(selectedUser)
        });

        if (resp.ok) { getUsers(); setLoading(false); setErrorMessage('')}

        else if (resp.status === 400 || resp.status === 404) {
            var data = await resp.json();
            setErrorMessage(data.message);
            setLoading(false);
        }
    }

    const editUser = async () => {

        setLoading(true);        

        var resp = await fetch(apiPath + 'users/editUser', {
            method: 'PUT', mode: 'cors', cache: 'no-cache', credentials: 'same-origin',
            headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(selectedUser)
        });

        if (resp.ok) { getUsers(); setLoading(false)}
    }

    const verifyRequest = async (link) => {

        setLoading(true);
        setLoadingMessage("VERIFYING REQUEST ...");

        link = link.replace(forgotPage, "");

        var resp = await fetch(apiPath + 'users/verifyChangeRequest?link=' + link, {
            method: 'POST', mode: 'cors', cache: 'no-cache', credentials: 'same-origin',
            headers: { 'Content-Type': 'application/json' }
        });

        if (resp.ok) { var data = await resp.json(); setForgotRequest(data); setLoading(false) }

        else if (resp.status === 400 || resp.status === 404) {
            var err = await resp.json();
            setErrorMessage(err.message);
            setLoading(false);
        }
    }

    const initiateForgotPassword = async () => {

        var resp = await fetch(apiPath + 'users/initiateForgotPassword', {
            method: 'POST', mode: 'cors', cache: 'no-cache', credentials: 'same-origin',
            headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(forgotRequest)
        });

        if (resp.ok) { document.getElementById('loginModal').checked = false; setErrorMessage('');}

        else if (resp.status === 400 || resp.status === 404) {
            var err = await resp.json();
            setErrorMessage(err.message);            
        }
    }

    const performForgotPassword = async (password) => {

        setLoading(true);
        setLoadingMessage("CHANGING PASSWORD ...");

        forgotRequest.password = password;

        var resp = await fetch(apiPath + 'users/performForgotPassword', {
            method: 'POST', mode: 'cors', cache: 'no-cache', credentials: 'same-origin',
            headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(forgotRequest)
        });

        if (resp.ok) { setLoading(false); setForgotRequest(); history.push('/home') }
    }

    const performChangePassword = async (password) => {

        setLoading(true);
        setLoadingMessage("CHANGING PASSWORD ...");

        const request = {
            password: password,
            username: loggedUser.username,
            email: loggedUser.email
        }        

        var resp = await fetch(apiPath + 'users/performChangePassword', {
            method: 'POST', mode: 'cors', cache: 'no-cache', credentials: 'same-origin',
            headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(request)
        });

        if (resp.ok) { setLoading(false); history.push('/home') }
    }

    return (
        <UsersContext.Provider value={{
            users, getUsers,
            user, setUser,
            newUser, setNewUser,
            loggedUser, setLoggedUser,
            performLogin, performLogout,
            checkLoggedUser, createUser,
            selectedUser, setSelectedUser,
            editUser, deleteUser,
            forgotRequest, setForgotRequest,
            initiateForgotPassword, performForgotPassword, verifyRequest,
            errorMessage, setErrorMessage,
            performChangePassword
        }}>
            {props.children}
        </UsersContext.Provider>
    )
}

export default UsersContextProvider;