import React from 'react'

const AboutUs = () => {

    return (
        <div className='py-5 grid lg:grid-cols-2 gap-5'>
            <div className='text-justify mx-10 my-auto'>
                <div className='text-blue-900 font-bold text-4xl text-center'>ABOUT US</div>
                <div className="my-auto">
                    <div className='my-5'>
                        <b>Van Gold is a high tech enterprise specialized in thermal receipt printer</b>, Label bar code printer, panel printer and kiosk printer. We can offer one-stop services from main board design, materials assembling, production, sales and delivery. We aim to be the leader manufacture in printer hardware solutions and services.
                    </div>
                    <div className='my-5'>
                        We rely on research and development strength, personalized solutions, stable product quality, scientific management,rich marketing experience and perfect market channels. Now we have established a stable marketing network at home and abroad with products sold to more than 50 countries and areas.
                    </div>
                </div>
            </div>
            <figure><img className='lg:rounded-tl-xl h-96 lg:rounded-bl-xl drop-shadow-md' src={require('../../Assets/Images/Home/About.png')} /></figure>
        </div>
    )
}

export default AboutUs