import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { ProductsContext } from '../../Contexts/ProductsContext'
import { Link } from 'react-router-dom'
import { UsersContext } from '../../Contexts/UsersContext'

const Product = ({ product }) => {

    const { loggedUser } = useContext(UsersContext);
    const { setSelectedProduct } = useContext(ProductsContext);

    const openModal = (e) => {

        e.preventDefault();
        document.getElementById('editProductModal').checked = true;
        setSelectedProduct(product);
    }

    const openHideShowModal = (e) => {

        e.preventDefault();
        e.stopPropagation();
        document.getElementById('hideShowProductModal').checked = true;
        setSelectedProduct(product);
    }

    const OpenDeleteProductModal = (e) => {

        e.preventDefault();
        e.stopPropagation();
        document.getElementById('deleteProductModal').checked = true;
        setSelectedProduct(product);
    }

    return (
        <div className="bg-vangold text-blue-900 drop-shadow-md card hover:scale-105 duration-500 overflow-hidden cursor-pointer" onClick={() => setSelectedProduct(product)}>
            <Link to={'/productDetails/' + product.productID}>
                <figure><img className='h-60 w-96 object-cover' src={product.productImage} alt={product.productName} /></figure>
                <div className="card-body text-center">
                    <h2 className="font-bold text-lg">{product.productName}</h2>
                    <p className='text-xs'>{product.productSummary}</p>
                    {
                        product.productPrice > 0 ?
                            <p>{product.productPrice} $</p> : null
                    }
                    {
                        loggedUser ?
                            <div className='grid grid-cols-3 pt-5 text-center'>
                                <div>
                                    {
                                        loggedUser.canUpdate ?
                                            product.isHidden ?
                                                <FontAwesomeIcon icon={solid('eye')} className="hover:scale-150 duration-500 hover:cursor-pointer hover:text-slate-600" onClick={(e) => openHideShowModal(e)} />
                                                :
                                                <FontAwesomeIcon icon={solid('eye-slash')} className="hover:scale-150 duration-500 hover:cursor-pointer hover:text-slate-600" onClick={(e) => openHideShowModal(e)} />
                                            : null
                                    }
                                </div>
                                {
                                    loggedUser.canDelete ?
                                        <div>
                                            <FontAwesomeIcon icon={solid('trash')} className="hover:scale-150 duration-500 hover:cursor-pointer hover:text-slate-600" onClick={(e) => OpenDeleteProductModal(e)} />
                                        </div> : null
                                }
                                {
                                    loggedUser.canUpdate ?
                                        <div>
                                            <FontAwesomeIcon icon={solid('pen-to-square')} className="hover:scale-150 duration-500 hover:cursor-pointer hover:text-slate-600" onClick={(e) => openModal(e)} />
                                        </div> : null
                                }
                            </div> : null
                    }
                </div>
            </Link>
        </div>
    )
}

export default Product