import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { QuestionsContext } from '../../../Contexts/QuestionsContext'
import { ProductsContext } from '../../../Contexts/ProductsContext'

const CreateQuestionModal = () => {

    const { question, setQuestion, createQuestion } = useContext(QuestionsContext);
    const { selectedProduct } = useContext(ProductsContext);

    return (
        <div>
            <input type="checkbox" id="createQuestionModal" className="modal-toggle" />
            <div className="modal modal-bottom sm:modal-middle">
                <div className="modal-box bg-blue-900">
                    <h3 className="font-bold text-lg mb-5 text-indigo-100">ASK ABOUT THIS PRODUCT</h3>
                    <div className="flex relative">
                        <input className="modal-input" type="search" placeholder="NAME" value={question ? question.name : ''} onChange={(e) => setQuestion({ ...question, name: e.target.value })} />
                        <FontAwesomeIcon icon={solid('user')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                    </div>
                    <div className="flex relative">
                        <input className="modal-input" type="search" placeholder="PHONE NUMBER" value={question ? question.phoneNumber : ''} onChange={(e) => setQuestion({ ...question, phoneNumber: e.target.value })} />
                        <FontAwesomeIcon icon={solid('phone')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                    </div>
                    <div className="flex relative">
                        <input className="modal-input" type="search" placeholder="EMAIL ADDRESS" value={question ? question.email : ''} onChange={(e) => setQuestion({ ...question, email: e.target.value })} />
                        <FontAwesomeIcon icon={solid('envelope')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                    </div>
                    <textarea className='modal-textarea pt-2' type='text' placeholder="INQUIRY" value={question ? question.description : ''} onChange={(e) => setQuestion({ ...question, description: e.target.value })} />
                    <div className="modal-action">
                        <label htmlFor="createQuestionModal" className="btn modal-action-btn" onClick={() => createQuestion(selectedProduct ? selectedProduct.productName : '')}>SUBMIT</label>
                        <label htmlFor="createQuestionModal" className="btn modal-action-btn">CLOSE</label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateQuestionModal;