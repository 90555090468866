import React, { useContext } from 'react'
import { UsersContext } from '../../Contexts/UsersContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

const EditUserModal = () => {

    const { selectedUser, setSelectedUser, editUser } = useContext(UsersContext);

    return (
        <div>
            <input type="checkbox" id="editUserModal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box w-11/12 max-w-5xl bg-blue-900">
                    <h3 className="font-bold text-lg mb-5 text-indigo-100">EDIT USER</h3>
                    <div className="flex relative my-5">
                        <input className="product-modal-input" type="search" placeholder="USERNAME" value={selectedUser ? selectedUser.username : ''} onChange={(e) => setSelectedUser({ ...selectedUser, username: e.target.value })} />
                        <FontAwesomeIcon icon={solid('user')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                    </div>
                    <div className="flex relative my-5">
                        <input className="product-modal-input" type="password" placeholder="PASSWORD" value={selectedUser ? selectedUser.password : ''} onChange={(e) => setSelectedUser({ ...selectedUser, password: e.target.value })} />
                        <FontAwesomeIcon icon={solid('lock')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                    </div>
                    <div className="flex relative my-5">
                        <input className="product-modal-input" type="search" placeholder="EMAIL" value={selectedUser ? selectedUser.email : ''} onChange={(e) => setSelectedUser({ ...selectedUser, email: e.target.value })} />
                        <FontAwesomeIcon icon={solid('envelope')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                    </div>
                    <div className='flex justify-center'>
                        <div className="form-control mx-10">
                            <label className="label cursor-pointer">
                                <span className="label-text text-vangold mx-5">IS ADMIN</span>
                                <input type="checkbox" className="checkbox checkbox-info" checked={selectedUser ? selectedUser.isAdmin : false} onChange={() => setSelectedUser({ ...selectedUser, isAdmin: !selectedUser.isAdmin })} />
                            </label>
                        </div>
                        <div className="form-control mx-10">
                            <label className="label cursor-pointer">
                                <span className="label-text text-vangold mx-5">CAN CREATE</span>
                                <input type="checkbox" className="checkbox checkbox-info" checked={selectedUser ? selectedUser.canCreate : false} onChange={() => setSelectedUser({ ...selectedUser, canCreate: !selectedUser.canCreate })} />
                            </label>
                        </div>
                        <div className="form-control mx-10">
                            <label className="label cursor-pointer">
                                <span className="label-text text-vangold mx-5">CAN UPDATE</span>
                                <input type="checkbox" className="checkbox checkbox-info" checked={selectedUser ? selectedUser.canUpdate : false} onChange={() => setSelectedUser({ ...selectedUser, canUpdate: !selectedUser.canUpdate })} />
                            </label>
                        </div>
                        <div className="form-control mx-10">
                            <label className="label cursor-pointer">
                                <span className="label-text text-vangold mx-5">CAN DELETE</span>
                                <input type="checkbox" className="checkbox checkbox-info" checked={selectedUser ? selectedUser.canDelete : false} onChange={() => setSelectedUser({ ...selectedUser, canDelete: !selectedUser.canDelete })} />
                            </label>
                        </div>
                    </div>
                    <div className="modal-action">
                        <label htmlFor="editUserModal" className="modal-action-btn" onClick={() => editUser()}>EDIT</label>
                        <label htmlFor="editUserModal" className="modal-action-btn">CLOSE</label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditUserModal