import React from 'react'

const Services = () => {
    return (
        <div className='grid md:grid-cols-2 lg:grid-cols-4 gap-5 my-3 px-10'>
            <div className='bg-slate-200 rounded-xl drop-shadow-xl p-5 text-center'>
                <figure><img className='mx-auto my-2' src={require('../../Assets/Images/Home/Services/Delivery.png')} alt='CERTIFIED' /></figure>
                <div className='font-bold my-2'>Fast Delivery</div>
                <div className='my-2'>High quality, fast delivery, sample 3-5 days, bulk order 15 days. Products sold to more than 50 countries and areas.</div>
            </div>
            <div className='bg-slate-200 rounded-xl drop-shadow-xl p-5 text-center'>
                <figure><img className='mx-auto my-2' src={require('../../Assets/Images/Home/Services/RDTeam.png')} alt='CERTIFIED' /></figure>
                <div className='font-bold my-2'>R&D Team</div>
                <div className='my-2'>We can provide OEM and ODM support. All engineers with more than 10 years experiences in thermal printers.</div>
            </div>
            <div className='bg-slate-200 rounded-xl drop-shadow-xl p-5 text-center'>
                <figure><img className='mx-auto my-2' src={require('../../Assets/Images/Home/Services/Settings.png')} alt='CERTIFIED' /></figure>
                <div className='font-bold my-2'>One-Stop Services</div>
                <div className='my-2'>We can offer one-stop services from main board design, materials assembling, production, sales and delivery.</div>
            </div>
            <div className='bg-slate-200 rounded-xl drop-shadow-xl p-5 text-center'>
                <figure><img className='mx-auto my-2' src={require('../../Assets/Images/Home/Services/Certificate.png')} alt='CERTIFIED' /></figure>
                <div className='font-bold my-2'>Products Certification</div>
                <div className='my-2'>We now own 3 series, 20 kinds of products, all our products passed CCC, CE, FCC, ROHS, CB, BIS certification</div>
            </div>
        </div>
    )
}

export default Services