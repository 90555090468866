import React, { useContext, useEffect } from 'react'
import { ProductsContext } from '../../Contexts/ProductsContext'
import CreateSpecificationModal from './ProductModals/CreateSpecificationModal';
import DeleteSpecificationModal from './ProductModals/DeleteSpecificationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import SpecificationRecords from './SpecificationRecords';
import { PhotosContext } from '../../Contexts/PhotosContext';
import Photos from '../Photos/Photos';
import PhotosGalleryModal from '../Photos/PhotoModals/PhotosGalleryModal';
import CreatePhotoModal from '../Photos/PhotoModals/CreatePhotoModal';
import CreateQuestionModal from '../Questions/QuestionModals/CreateQuestionModal';
import { UsersContext } from '../../Contexts/UsersContext';
import ProductDownload from './ProductDownload';
import ProductVideo from './ProductVideo';
import Loading from '../Loading/Loading';
import { LoadingContext } from '../../Contexts/LoadingContext';

const ProductDetails = () => {

    const { loggedUser } = useContext(UsersContext);
    const { selectedProduct, getProduct } = useContext(ProductsContext);
    const { setDisplayedPhoto } = useContext(PhotosContext);
    const { loading } = useContext(LoadingContext);

    const openPhotoGalleryModal = (photo) => {

        setDisplayedPhoto(photo);
        document.getElementById('photoGalleryModal').checked = true;
    }

    const openCreatePhotoModal = () => {

        document.getElementById('createPhotoModal').checked = true;
    }

    const openQuestionModal = () => {

        document.getElementById('createQuestionModal').checked = true;
    }

    useEffect(() => {
        if (selectedProduct === undefined)
            getProduct(window.location.href)
    }, []);

    return (
        loading ? <Loading /> :

            selectedProduct &&
            <div className='grid md:grid-cols-3 gap-5 mx-10 py-5'>
                <div className='col-span-2 rounded-md drop-shadow-md'>
                    <div className='grid md:grid-cols-3 gap-5'>
                        <div className='text-start font-bold text-2xl col-span-2'>{selectedProduct.productName}</div>
                    </div>
                    <div className='text-start font-bold text-xl my-5'>{selectedProduct.productPrice} $</div>
                    <div className='text-start my-5'>{selectedProduct.productDescription}</div>
                    <SpecificationRecords />
                    {
                        selectedProduct.downloads && selectedProduct.downloads.length > 0 && selectedProduct.downloads.filter(d => d.type === 'Driver').length > 0 ?
                            <div>
                                <div className='text-start text-lg font-bold text-black mt-10 mb-5'>DRIVERS</div>
                                <div className='border-black border-b-2' />
                                {
                                    selectedProduct.downloads.filter(d => d.type === 'Driver').map((download) => (
                                        <ProductDownload downloadable={download} key={download.downloadableID} />
                                    ))
                                }
                            </div>
                            : null
                    }
                    {
                        selectedProduct.downloads && selectedProduct.downloads.length > 0 && selectedProduct.downloads.filter(d => d.type === 'UserManual').length > 0 ?
                            <div>
                                <div className='text-start text-lg font-bold text-black mt-10 mb-5'>USER MANUALS</div>
                                <div className='border-black border-b-2' />
                                {
                                    selectedProduct.downloads.filter(d => d.type === 'UserManual').map((download) => (
                                        <ProductDownload downloadable={download} key={download.downloadableID} />
                                    ))
                                }
                            </div>
                            : null
                    }
                    {
                        selectedProduct.downloads && selectedProduct.downloads.length > 0 && selectedProduct.downloads.filter(d => d.type === 'Setting').length > 0 ?
                            <div>
                                <div className='text-start text-lg font-bold text-black mt-10 mb-5'>APPLICATIONS / SETTINGS</div>
                                <div className='border-black border-b-2' />
                                {
                                    selectedProduct.downloads.filter(d => d.type === 'Setting').map((download) => (
                                        <ProductDownload downloadable={download} key={download.downloadableID} />
                                    ))
                                }
                            </div>
                            : null
                    }
                    {
                        selectedProduct.downloads && selectedProduct.downloads.length > 0 && selectedProduct.downloads.filter(d => d.type === 'Introduction').length > 0 ?
                            <div>
                                <div className='text-start text-lg font-bold text-black mt-10 mb-5'>INTRODUCTION DOCUMENTS</div>
                                <div className='border-black border-b-2' />
                                {
                                    selectedProduct.downloads.filter(d => d.type === 'Introduction').map((download) => (
                                        <ProductDownload downloadable={download} key={download.downloadableID} />
                                    ))
                                }
                            </div>
                            : null
                    }
                    {
                        selectedProduct.downloads && selectedProduct.downloads.length > 0 && selectedProduct.downloads.filter(d => d.type === 'Video').length > 0 ?
                            <div>
                                <div className='text-start text-lg font-bold text-black mt-10 mb-5'>VIDEOS</div>
                                <div className='border-black border-b-2' />
                                {
                                    selectedProduct.downloads.filter(d => d.type === 'Video').map((download) => (
                                        <ProductVideo downloadable={download} key={download.downloadableID} />
                                    ))
                                }
                            </div>
                            : null
                    }
                </div>
                <div className='rounded-md drop-shadow-md overflow-hidden p-3 mx-auto'>
                    <button type="submit" className="printers-button mb-5" onClick={() => openQuestionModal()}>
                        <span className='mr-2'>ASK US</span>
                        <FontAwesomeIcon icon={solid('question')} />
                    </button>
                    {
                        loggedUser && loggedUser.canCreate ?
                            <button type="submit" className="printers-button mb-5" onClick={() => openCreatePhotoModal()}>
                                <span className='mr-2'>ADD PHOTOS</span>
                                <FontAwesomeIcon icon={solid('images')} />
                            </button> : null
                    }
                    <figure><img className='ring-1 ring-slate-500 object-cover rounded-md hover:cursor-zoom-in' src={selectedProduct.productImage} alt={selectedProduct.productName} onClick={() => openPhotoGalleryModal(selectedProduct.productImage)} /></figure>
                    <Photos />
                </div>
                <CreateSpecificationModal />
                <DeleteSpecificationModal />
                <CreatePhotoModal />
                <PhotosGalleryModal />
                <CreateQuestionModal />
            </div>
    )
}

export default ProductDetails