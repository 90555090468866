import React, { useContext } from 'react'
import { CategoriesContext } from '../../Contexts/CategoriesContext'
import Category from './Category';

const CategoryRecords = () => {

    const { categories } = useContext(CategoriesContext);

    return (
        <div className='relative'>
            {
                categories && categories.length > 0 ?
                    categories.map((category) => (
                        <Category category={category} key={category.categoryID} />
                    ))
                    : <div className='text-xl text-center mx-auto my-10'>THERE ARE NO PRODUCTS TO VIEW</div>
            }
        </div>
    )
}

export default CategoryRecords