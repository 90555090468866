import { useContext } from "react";
import { createContext, useState } from "react";
import configData from '../config.json';
import { LoadingContext } from "./LoadingContext";

export const CategoriesContext = createContext();

const CategoriesContextProvider = (props) => {
    
    const apiPath = configData.apiPath;
    const { setLoading, setLoadingMessage } = useContext(LoadingContext);

    const [category, setCategory] = useState();
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState();
    
    const getCategories = async () => {

        setLoading(true);
        setLoadingMessage("GETTING PRODUCTS ...");
        var resp = await fetch(apiPath + 'categories/get');        
        if (resp.ok) { var data = await resp.json(); setCategories(data); setLoading(false); }
    }

    const createCategory = async () => {
        
        setLoading(true);
        setLoadingMessage("CREATING CATEGORY ...");
        var resp = await fetch(apiPath + 'categories/create', {
            method: 'POST', mode: 'cors', cache: 'no-cache', credentials: 'same-origin',
            headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(category)
        });

        if (resp.ok) { getCategories(); setCategory(null); setLoading(false); } 
    }

    const deleteCategory = async (category) => {
        
        setLoading(true);
        setLoadingMessage("DELETING CATEGORY ...");
        var resp = await fetch(apiPath + 'categories/delete', {
            method: 'DELETE', mode: 'cors', cache: 'no-cache', credentials: 'same-origin',
            headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(category)
        });

        if (resp.ok) { getCategories(); setLoading(false); } 
    }

    const updateCategory = async (category) => {
        
        setLoading(true);
        setLoadingMessage("DELETING CATEGORY ...");
        var resp = await fetch(apiPath + 'categories/update', {
            method: 'PUT', mode: 'cors', cache: 'no-cache', credentials: 'same-origin',
            headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(category)
        });

        if (resp.ok) { getCategories(); setLoading(false); } 
    }

    const hideShowCategory = async (categoryID) => {
        
        setLoading(true);
        setLoadingMessage("CHANGING CATEGORY'S VISIBILITY ...");
        var resp = await fetch(apiPath + 'categories/hideShow?categoryID=' + categoryID,
        {
            method: 'PATCH', mode: 'cors', cache: 'no-cache', credentials: 'same-origin',
            headers: { 'Content-Type': 'application/json' }
        });

        if (resp.ok) { getCategories(); setLoading(false); }
    }

    return(
        <CategoriesContext.Provider value={{
            category, setCategory,
            categories, setCategories,
            createCategory, getCategories,
            deleteCategory, hideShowCategory,
            updateCategory,
            selectedCategory, setSelectedCategory
        }}>
            {props.children}
        </CategoriesContext.Provider>
    )
}

export default CategoriesContextProvider;