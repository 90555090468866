import React, { useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { DownloadablesContext } from '../../../Contexts/DownloadablesContext'
import CreateUserManualModal from './UserManualModals/CreateUserManualModal'
import DeleteUserManualModal from './UserManualModals/DeleteUserManualModal'
import UserManual from './UserManual'
import { UsersContext } from '../../../Contexts/UsersContext'
import Loading from '../../Loading/Loading'
import { LoadingContext } from '../../../Contexts/LoadingContext'

const UserManuals = () => {

    const { loggedUser } = useContext(UsersContext);
    const { getDownloadables, searchDownloadables, downloadables } = useContext(DownloadablesContext);
    const { loading } = useContext(LoadingContext);

    const openCreateUserManualModal = () => {

        document.getElementById('createUserManualModal').checked = true;
    }

    useEffect(() => {
        getDownloadables('UserManual');
    }, [])

    return (
        loading ? <Loading /> :
        <div className='relative mx-10 py-5'>
            <div className='grid md:grid-cols-2 lg:grid-cols-4 gap-5'>
                {
                    loggedUser && loggedUser.canCreate ?
                        <div className="flex relative">
                            <button type="submit" className="printers-button" onClick={() => openCreateUserManualModal()}>
                                <span className='mr-2'>CREATE USER MANUAL</span>
                                <FontAwesomeIcon icon={solid('plus')} />
                            </button>
                        </div> : null
                }
                <div className="flex relative lg:col-start-4">
                    <input className="printers-input" type="search" placeholder="SEARCH" onChange={(e) => searchDownloadables(e.target.value, 'UserManual')} />
                    <FontAwesomeIcon icon={solid('search')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                </div>
            </div>
            {
                downloadables && downloadables.length > 0 ?
                    downloadables.map((userManual) => (
                        <UserManual userManual={userManual} key={userManual.downloadableID} />
                    ))
                    : <div className='text-xl text-center mx-auto my-10'>THERE ARE NO FILES TO DOWNLOAD</div>
            }
            <CreateUserManualModal />
            <DeleteUserManualModal />
        </div>
    )
}

export default UserManuals