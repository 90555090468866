import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Logo from '../../Assets/Images/LOGO.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { QuestionsContext } from '../../Contexts/QuestionsContext';
import LoginModal from '../Login/LoginModal';
import { UsersContext } from '../../Contexts/UsersContext';
import LogoutModal from '../Login/LogoutModal';

const Navigation = () => {

    const { loggedUser } = useContext(UsersContext);
    const { count, getQuestionsCount } = useContext(QuestionsContext);
    const style = 'grid lg:col-span-4 md:col-span-2 grid-cols-4 gap-5';
    const userStyle = 'grid lg:col-span-4 md:col-span-2 grid-cols-5 gap-5';

    const openModal = () => {

        if (loggedUser === null)
            document.getElementById('loginModal').checked = true;
        else
            document.getElementById('logoutModal').checked = true;
    }

    useEffect(() => {

        if (loggedUser)
        {
            getQuestionsCount();
        }

    }, []);

    return (
        <div className='bg-gradient-to-r from-blue-900 to-blue-600 grid lg:grid-cols-6 md:grid-cols-4 sticky top-0 z-40'>
            <div className='px-5 text-center mx-auto'>
                <img className='object-scale-down h-20 w-40' src={Logo} />
            </div>
            <div className={loggedUser && loggedUser.isAdmin ? userStyle : style}>
                <Link to='/home' className='my-auto text-center'>
                    <div className='navigation-item text-xs'>HOME</div>
                </Link>
                <Link to='/products' className='my-auto text-center'>
                    <div className='navigation-item text-xs'>PRODUCTS</div>
                </Link>
                <Link to='/downloads' className='my-auto text-center'>
                    <div className='navigation-item text-xs'>DOWNLOADS</div>
                </Link>
                <Link to='/contact' className='my-auto text-center'>
                    <div className='navigation-item text-xs'>CONTACT</div>
                </Link>
                {
                    loggedUser && loggedUser.isAdmin ?
                        <Link to='/users' className='my-auto text-center'>
                            <div className='navigation-item text-xs'>USERS</div>
                        </Link>
                        : null
                }
            </div>
            <div className='my-5 px-5 mx-auto grid grid-cols-2'>
                {
                    loggedUser ?
                        <Link to='/questions' className='flex mx-5 hover:scale-150 duration-300'>
                            <FontAwesomeIcon icon={solid('envelope')} className="my-auto text-white" />
                            <div className='text-xs my-auto mx-2 text-white'>{count}</div>
                        </Link>
                        : null
                }
                <div className='flex mx-5 hover:scale-150 duration-300 hover:cursor-pointer' onClick={() => openModal()}>
                    <FontAwesomeIcon icon={solid('user')} className="my-auto text-white" />
                    <div className='text-xs my-auto mx-1 text-white'>{loggedUser ? loggedUser.username : ''}</div>
                </div>                
            </div>
            <LoginModal />
            <LogoutModal />
        </div>
    )
}

export default Navigation