import { createContext, useContext, useState } from "react";
import configData from '../config.json'
import { CategoriesContext } from "./CategoriesContext";
import { LoadingContext } from "./LoadingContext";

export const ProductsContext = createContext();

const ProductsContextProvider = (props) => {

    const apiPath = configData.apiPath;
    const productLink = configData.productLink;

    const { setLoading, setLoadingMessage } = useContext(LoadingContext);
    const { getCategories } = useContext(CategoriesContext);

    const [product, setProduct] = useState();
    const [products, setProducts] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState();
    const [categoryID, setCategoryID] = useState(0);
    const [file, setFile] = useState();

    const getProducts = async () => {

        var resp = await fetch(apiPath + 'products/get');
        if (resp.ok) { var data = await resp.json(); setProducts(data); }
    }

    const getProduct = async (productID) => {

        var resp = await fetch(apiPath + 'products/getProduct?productID=' + productID.replace(productLink, ''))
        if (resp.ok) { var data = await resp.json(); setSelectedProduct(data); }
    }

    const getProductNames = async () => {

        var resp = await fetch(apiPath + 'products/getAll');
        if (resp.ok) { var data = await resp.json(); setAllProducts(data); }
    }

    const createProduct = async () => {

        setLoading(true);
        setLoadingMessage("CREATING PRODUCT ...");

        const formData = new FormData();
        formData.append("productName", product.productName);
        if (product.productSummary != undefined)
            formData.append("productSummary", product.productSummary);
        if (product.productDescription != undefined)
            formData.append("productDescription", product.productDescription);
        if (product.productPrice != undefined)
            formData.append("productPrice", product.productPrice ? product.productPrice : null);
        formData.append("filename", product.filename);
        formData.append("file", file);
        formData.append("categoryID", categoryID);

        var resp = await fetch(apiPath + 'products/create', {
            method: 'POST', cache: 'no-cache', credentials: 'same-origin', body: formData
        });

        if (resp.ok) { setProduct(null); getCategories(); setLoading(false) }
    }

    const deleteProduct = async (product) => {

        setLoading(true);
        setLoadingMessage("DELETING PRODUCT ...");

        var resp = await fetch(apiPath + 'products/delete', {
            method: 'DELETE', mode: 'cors', cache: 'no-cache', credentials: 'same-origin',
            headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(product)
        });

        if (resp.ok) { getCategories(); setLoading(false) }
    }

    const updateProduct = async () => {

        setLoading(true);
        setLoadingMessage("UPDATING PRODUCTS ...");

        const formData = new FormData();
        formData.append("productID", selectedProduct.productID);
        formData.append("productName", selectedProduct.productName);
        if (selectedProduct.productSummary != undefined)
            formData.append("productSummary", selectedProduct.productSummary);
        if (selectedProduct.productImage != undefined)
            formData.append("productImage", selectedProduct.productImage);
        if (selectedProduct.productDescription != undefined)
            formData.append("productDescription", selectedProduct.productDescription);
        if (selectedProduct.productPrice != undefined)
            formData.append("productPrice", selectedProduct.productPrice);
        formData.append("categoryID", selectedProduct.categoryID);
        if (selectedProduct.filename != undefined)
            formData.append("filename", selectedProduct.filename);
        if (file != undefined)
            formData.append("file", file);

        var resp = await fetch(apiPath + 'products/update', {
            method: 'PATCH', mode: 'cors', cache: 'no-cache', credentials: 'same-origin',
            body: formData
        });

        if (resp.ok) { getCategories(); setLoading(false) }
    }

    const hideShowProduct = async (productID) => {

        setLoading(true);
        setLoadingMessage("CHANGING PRODUCT'S VISIBILITY ...");

        var resp = await fetch(apiPath + 'products/hideShow?productID=' + productID,
            {
                method: 'PATCH', mode: 'cors', cache: 'no-cache', credentials: 'same-origin',
                headers: { 'Content-Type': 'application/json' }
            });

        if (resp.ok) { getCategories(); setLoading(false) }
    }

    return (
        <ProductsContext.Provider value={{
            product, setProduct, getProduct,
            products, setProducts,
            allProducts, setAllProducts,
            getProducts, createProduct,
            deleteProduct, hideShowProduct,
            updateProduct, getProductNames,
            categoryID, setCategoryID,
            selectedProduct, setSelectedProduct,
            file, setFile
        }}>
            {props.children}
        </ProductsContext.Provider>
    )
}

export default ProductsContextProvider;