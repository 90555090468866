import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FailuresContext } from '../../../Contexts/FailuresContext'
import { UsersContext } from '../../../Contexts/UsersContext'

const Failure = ({ failure }) => {

    const { loggedUser } = useContext(UsersContext);
    const { setSelectedFailure } = useContext(FailuresContext);

    const styleUser = 'grid grid-cols-4 gap-5 py-5 px-2 text-sm text-black text-center bg-slate-100 ring-1 ring-blue-900';
    const style = 'grid grid-cols-3 gap-5 py-5 px-2 text-sm text-black text-center bg-slate-100 ring-1 ring-blue-900';

    const openDeleteFailureModal = (e) => {

        e.preventDefault();
        document.getElementById('deleteFailureModal').checked = true;
        setSelectedFailure(failure);
    }

    const openEditFailureModal = (e) => {

        e.preventDefault();
        document.getElementById('editFailureModal').checked = true;
        setSelectedFailure(failure);
    }

    return (
        <div className={loggedUser ? styleUser : style}>
            <div>{failure.phenomenon}</div>
            <div>{failure.cause}</div>
            <div>{failure.solution}</div>
            {
                loggedUser ?
                    <div className="flex gap-5 justify-center my-2">
                        {
                            loggedUser && loggedUser.canDelete ? 
                            <FontAwesomeIcon icon={solid('trash')} className="text-black hover:scale-150 duration-200 hover:cursor-pointer hover:text-slate-600" onClick={(e) => openDeleteFailureModal(e)} /> : null
                        }
                        {
                            loggedUser && loggedUser.canUpdate ?
                            <FontAwesomeIcon icon={solid('pen-to-square')} className="text-black hover:scale-150 duration-200 hover:cursor-pointer hover:text-slate-600" onClick={(e) => openEditFailureModal(e)} /> : null
                        }                        
                    </div> : null
            }
        </div>
    )
}

export default Failure