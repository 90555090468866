import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { QuestionsContext } from '../../Contexts/QuestionsContext'

const Question = ({ question }) => {

    const { updateQuestion } = useContext(QuestionsContext);

    return (
        <div className='bg-slate-600 bg-opacity-70 rounded-lg drop-shadow-md my-5 p-5 grid lg:grid-cols-3 gap-5'>
            <div className='text-white text-center font-bold'>NAME : {question.name}</div>
            <div className='text-white text-center font-bold'>PHONE NUMBER : {question.phoneNumber}</div>            
            <div className='text-white text-center font-bold'>EMAIL ADDRESS : {question.email}</div>            
            {
                question.productName !== '' ?
                    <div className='col-span-3 text-center text-white my-5 font-bold'>REGARDING : {question.productName}</div> : null
            }
            <div className='col-span-3 text-center text-white my-5'>{question.description}</div>
            <button type="submit" className="home-button my-5 lg:col-start-2" onClick={() => updateQuestion(question.questionID)}>
                <span className='mr-2'>MARK AS REPLIED TO</span>
                <FontAwesomeIcon icon={solid('check')} className="text-white" />
            </button>
        </div>
    )
}

export default Question