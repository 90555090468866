import './App.css';
import Navigation from './Components/Navigation/Navigation';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './Components/Home/Home';
import Categories from './Components/Categories/Categories';
import CategoriesContextProvider from './Contexts/CategoriesContext';
import ProductsContextProvider from './Contexts/ProductsContext';
import Downloads from './Components/Downloads/Downloads';
import Drivers from './Components/Downloads/Drivers/Drivers';
import DownloadablesContextProvider from './Contexts/DownloadablesContext';
import UserManuals from './Components/Downloads/UserManuals/UserManuals';
import OperationVideos from './Components/Downloads/OperationVideos/OperationVideos';
import ApplicationSettings from './Components/Downloads/ApplicationSettings/ApplicationSettings';
import Introductions from './Components/Downloads/Introductions/Introductions';
import ProductDetails from './Components/Products/ProductDetails';
import SpecificationsContextProvider from './Contexts/SpecificationsContext';
import PhotosContextProvider from './Contexts/PhotosContext';
import QuestionsContextProvider from './Contexts/QuestionsContext';
import Contact from './Components/Contact/Contact';
import Footer from './Components/Navigation/Footer';
import Questions from './Components/Questions/Questions';
import PouchDBContextProvider from './Contexts/PouchDBContext';
import UsersContextProvider from './Contexts/UsersContext';
import LoadingContextProvider from './Contexts/LoadingContext';
import Users from './Components/Users/Users';
import ForgotPassword from './Components/ChangePassword/ForgotPassword';
import ChangePassword from './Components/ChangePassword/ChangePassword';
import ParagraphsContextProvider from './Contexts/ParagraphsContext';
import FailuresContextProvider from './Contexts/FailuresContext';

function App() {
  return (
    <Router>
      <div className='bg-slate-100'>
        <Switch>
          <LoadingContextProvider>
            <PouchDBContextProvider>
              <UsersContextProvider>
                <CategoriesContextProvider>
                  <ProductsContextProvider>
                    <DownloadablesContextProvider>
                      <SpecificationsContextProvider>
                        <PhotosContextProvider>
                          <QuestionsContextProvider>
                            <Navigation />
                            <Route exact path='/' component={Home} />
                            <Route path='/products' component={Categories} />
                            <Route path='/productDetails' component={ProductDetails} />
                            <FailuresContextProvider>
                              <ParagraphsContextProvider>
                                <Route path='/downloads' component={Downloads} />
                              </ParagraphsContextProvider>
                            </FailuresContextProvider>
                            <Route path='/drivers' component={Drivers} />
                            <Route path='/userManuals' component={UserManuals} />
                            <Route path='/operationVideos' component={OperationVideos} />
                            <Route path='/appSettings' component={ApplicationSettings} />
                            <Route path='/productIntroductions' component={Introductions} />
                            <Route path='/home' component={Home} />
                            <Route path='/contact' component={Contact} />
                            <Route path='/questions' component={Questions} />
                            <Route path='/users' component={Users} />
                            <Route path='/forgot' component={ForgotPassword} />
                            <Route path='/change' component={ChangePassword} />
                          </QuestionsContextProvider>
                        </PhotosContextProvider>
                      </SpecificationsContextProvider>
                    </DownloadablesContextProvider>
                  </ProductsContextProvider>
                </CategoriesContextProvider>
              </UsersContextProvider>
            </PouchDBContextProvider>
          </LoadingContextProvider>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
