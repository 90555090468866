import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { QuestionsContext } from '../../Contexts/QuestionsContext'
import Loading from '../Loading/Loading'
import { LoadingContext } from '../../Contexts/LoadingContext'

const SendToUs = () => {

    const { loading } = useContext(LoadingContext);
    const { question, setQuestion, createQuestion } = useContext(QuestionsContext);

    return (
        loading ? <Loading /> :
        <div className='contact-us py-10 my-10'>
            <div className='font-bold text-white text-4xl'>
                GET IN TOUCH WITH US
            </div>
            <div className='text-md mx-10 text-white my-10 drop-shadow-md'>
                We rely on research and development strength, personalized solutions, stable product quality, scientific management,rich marketing experience and perfect market channels. If you have a project then please get in touch and we can discuss your requirements and needs. View more cases we have accomplished to learn more details of our services
            </div>
            <div className='grid grid-cols-3 gap-5 mx-10 my-10'>
                <div className="flex relative">
                    <input className="product-modal-input" type="text" placeholder="NAME" value={question ? question.name : ''} onChange={(e) => setQuestion({...question, name:e.target.value})} />
                    <FontAwesomeIcon icon={solid('user')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                </div>
                <div className="flex relative">
                    <input className="product-modal-input" type="text" placeholder="PHONE" value={question ? question.phoneNumber : ''} onChange={(e) => setQuestion({...question, phoneNumber:e.target.value})} />
                    <FontAwesomeIcon icon={solid('phone')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                </div>
                <div className="flex relative">
                    <input className="product-modal-input" type="text" placeholder="EMAIL" value={question ? question.email : ''} onChange={(e) => setQuestion({...question, email:e.target.value})} />
                    <FontAwesomeIcon icon={solid('envelope')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                </div>
                <textarea className='modal-textarea pt-2 col-span-3 drop-shadow-md' type='text' placeholder="QUESTION" value={question ? question.description : ''} onChange={(e) => setQuestion({...question, description:e.target.value})} />
            </div>
            <div className="my-7">
                <button type="submit" className="home-button" onClick={() => createQuestion('')}>
                    <span className='mr-2'>SEND INQUIRY NOW</span>
                </button>
            </div>
        </div>
    )
}

export default SendToUs