import { createContext, useContext, useState } from "react";
import { LoadingContext } from "./LoadingContext";
import configData from '../config.json'

export const ParagraphsContext = createContext();

const ParagraphsContextProvider = (props) => {

    const apiPath = configData.apiPath;

    const { setLoading, setLoadingMessage } = useContext(LoadingContext);

    const [paragraphs, setParagraphs] = useState([]);
    const [paragraph, setParagraph] = useState();
    const [selectedParagraph, setSelectedParagraph] = useState();

    const getParagraphs = async () => {

        setLoading(true);
        setLoadingMessage("GETTING PARAGRAPHS ...");
        var resp = await fetch(apiPath + 'paragraphs/get');        
        if (resp.ok) { var data = await resp.json(); setParagraphs(data); setLoading(false); }
    }

    const createParagraph = async () => {
        
        setLoading(true);
        setLoadingMessage("CREATING PARAGRAPH ...");
        var resp = await fetch(apiPath + 'paragraphs/create', {
            method: 'POST', mode: 'cors', cache: 'no-cache', credentials: 'same-origin',
            headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(paragraph)
        });

        if (resp.ok) { getParagraphs(); setParagraph(null); setLoading(false); }
    }

    const deleteParagraph = async () => {
        
        setLoading(true);
        setLoadingMessage("DELETING PARAGRAPH ...");
        var resp = await fetch(apiPath + 'paragraphs/delete', {
            method: 'DELETE', mode: 'cors', cache: 'no-cache', credentials: 'same-origin',
            headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(selectedParagraph)
        });

        if (resp.ok) { getParagraphs(); setLoading(false); } 
    }

    const updateParagraph = async () => {
        
        setLoading(true);
        setLoadingMessage("DELETING PARAGRAPH ...");
        var resp = await fetch(apiPath + 'paragraphs/update', {
            method: 'PUT', mode: 'cors', cache: 'no-cache', credentials: 'same-origin',
            headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(selectedParagraph)
        });

        if (resp.ok) { getParagraphs(); setLoading(false); } 
    }

    return(
        <ParagraphsContext.Provider value={{
            paragraphs, selectedParagraph, setSelectedParagraph,
            createParagraph, getParagraphs, deleteParagraph,
            updateParagraph, paragraph, setParagraph
        }}>
            {props.children}
        </ParagraphsContext.Provider>
    )
}

export default ParagraphsContextProvider;