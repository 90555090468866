import { createContext, useContext, useState } from "react";
import { LoadingContext } from "./LoadingContext";
import configData from '../config.json'

export const FailuresContext = createContext();

const FailuresContextProvider = (props) => {

    const apiPath = configData.apiPath;

    const { setLoading, setLoadingMessage } = useContext(LoadingContext);

    const [failures, setFailures] = useState([]);
    const [failure, setFailure] = useState();
    const [selectedFailure, setSelectedFailure] = useState();

    const getFailures = async () => {

        setLoading(true);
        setLoadingMessage("GETTING FAILURES ...");
        var resp = await fetch(apiPath + 'failures/get');        
        if (resp.ok) { var data = await resp.json(); setFailures(data); setLoading(false); }
    }

    const createFailure = async () => {
        
        setLoading(true);
        setLoadingMessage("CREATING FAILURE ...");
        var resp = await fetch(apiPath + 'failures/create', {
            method: 'POST', mode: 'cors', cache: 'no-cache', credentials: 'same-origin',
            headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(failure)
        });

        if (resp.ok) { getFailures(); setFailure(null); setLoading(false); }
    }

    const deleteFailure = async () => {
        
        setLoading(true);
        setLoadingMessage("DELETING FAILURE ...");
        var resp = await fetch(apiPath + 'failures/delete', {
            method: 'DELETE', mode: 'cors', cache: 'no-cache', credentials: 'same-origin',
            headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(selectedFailure)
        });

        if (resp.ok) { getFailures(); setLoading(false); } 
    }

    const updateFailure = async () => {
        
        setLoading(true);
        setLoadingMessage("DELETING FAILURE ...");
        var resp = await fetch(apiPath + 'failures/update', {
            method: 'PUT', mode: 'cors', cache: 'no-cache', credentials: 'same-origin',
            headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(selectedFailure)
        });

        if (resp.ok) { getFailures(); setLoading(false); } 
    }

    return(
        <FailuresContext.Provider value={{
            failures, selectedFailure, setSelectedFailure,
            createFailure, getFailures, deleteFailure,
            updateFailure, failure, setFailure
        }}>
            {props.children}
        </FailuresContext.Provider>
    )
}

export default FailuresContextProvider;