import React, { useContext, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { ProductsContext } from '../../../Contexts/ProductsContext'

const EditProductModal = () => {

    const { selectedProduct, setSelectedProduct, updateProduct, setFile } = useContext(ProductsContext);

    const [uploadedPic, setUploadedPic] = useState('');

    const saveFile = (e) => {

        setFile(e.target.files[0]);
        setSelectedProduct({...selectedProduct, filename: e.target.files[0].name});
        setUploadedPic(e.target.files[0].name);
    }

    const openFileInput = () => {

        document.getElementById('editFileInput').click();
    }

    return (
        <div>
            <input type="checkbox" id="editProductModal" className="modal-toggle" />
            <div className="modal modal-bottom sm:modal-middle">
                <div className="modal-box bg-blue-900">
                    <h3 className="font-bold text-lg mb-5 text-indigo-100">UPDATE PRODUCT</h3>

                    <input id='editFileInput' className="hidden" type="file" onChange={(e) => saveFile(e)} />

                    <div className="flex relative mb-5">
                        <input id="editPic" value={uploadedPic ? uploadedPic : ''} type="text" className="product-modal-file-input" placeholder="PRODUCT IMAGE" onClick={() => openFileInput()} />
                        <FontAwesomeIcon icon={solid('image')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                    </div>

                    <div className="flex relative mb-5">
                        <input className="product-modal-input" type="search" placeholder="PRODUCT NAME" value={selectedProduct ? selectedProduct.productName : ''} onChange={(e) => setSelectedProduct({ ...selectedProduct, productName: e.target.value })} />
                        <FontAwesomeIcon icon={solid('print')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                    </div>
                    <div className="flex relative mb-5">
                        <input className="product-modal-input" type="search" placeholder="PRODUCT PRICE" value={selectedProduct ? selectedProduct.productPrice : ''} onChange={(e) => setSelectedProduct({ ...selectedProduct, productPrice: e.target.value })} />
                        <FontAwesomeIcon icon={solid('dollar-sign')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                    </div>
                    <div className="flex relative mb-5">
                        <input className="product-modal-input" type="search" placeholder="PRODUCT SUMMARY" value={selectedProduct ? selectedProduct.productSummary : ''} onChange={(e) => setSelectedProduct({ ...selectedProduct, productSummary: e.target.value })} />
                        <FontAwesomeIcon icon={solid('book')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                    </div>
                    <div className="flex relative">
                        <input className="product-modal-input" type="search" placeholder="PRODUCT DESCRIPTION" value={selectedProduct ? selectedProduct.productDescription : ''} onChange={(e) => setSelectedProduct({ ...selectedProduct, productDescription: e.target.value })} />
                        <FontAwesomeIcon icon={solid('book-open')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                    </div>
                    <div className="modal-action">
                        <label htmlFor="editProductModal" className="modal-action-btn hover:cursor-pointer" onClick={() => updateProduct(selectedProduct)}>EDIT</label>
                        <label htmlFor="editProductModal" className="modal-action-btn hover:cursor-pointer">CLOSE</label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditProductModal