import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { CategoriesContext } from '../../../Contexts/CategoriesContext'

const EditCategoryModal = () => {

    const { selectedCategory, setSelectedCategory, updateCategory } = useContext(CategoriesContext);

    return (
        <div>
            <input type="checkbox" id="editCategoryModal" className="modal-toggle" />
            <div className="modal modal-bottom sm:modal-middle">
                <div className="modal-box bg-blue-900">
                    <h3 className="font-bold text-lg mb-5 text-indigo-100">EDIT CATEGORY</h3>                    
                    <div className="flex relative col-start-4">
                        <input className="modal-input" type="search" placeholder="CATEGORY NAME" value={selectedCategory ? selectedCategory.categoryName : ''} onChange={(e) => setSelectedCategory({...selectedCategory, categoryName: e.target.value})} />
                        <FontAwesomeIcon icon={solid('grip-vertical')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                    </div>
                    <div className="flex relative col-start-4">
                        <input className="modal-input" type="search" placeholder="CATEGORY DESCRIPTION"  value={selectedCategory ? selectedCategory.categoryDescription : ''} onChange={(e) => setSelectedCategory({...selectedCategory, categoryDescription: e.target.value})}/>
                        <FontAwesomeIcon icon={solid('stream')} className="absolute right-2 top-3 mr-4 text-black w-6 h-6" />
                    </div>                    
                    <div className="modal-action">
                        <label htmlFor="editCategoryModal" className="modal-action-btn" onClick={() => updateCategory(selectedCategory)}>EDIT</label>
                        <label htmlFor="editCategoryModal" className="modal-action-btn">CLOSE</label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditCategoryModal