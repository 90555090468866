import React, { useContext } from 'react'
import Application from '../../../Assets/Images/Extensions/Application.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { DownloadablesContext } from '../../../Contexts/DownloadablesContext'
import { UsersContext } from '../../../Contexts/UsersContext'

const ApplicationSetting = ({ appSetting }) => {

    const { loggedUser } = useContext(UsersContext);
    const { setSelectedDownloadable, download } = useContext(DownloadablesContext);

    const openDeleteApplicationSettingModal = () => {

        document.getElementById('deleteApplicationSettingModal').checked = true;
        setSelectedDownloadable(appSetting);
    }

    const downloadFile = async () => {

        var data = await download(appSetting);
        let myBlob = await data.blob();
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(myBlob);
        a.download = appSetting.filename;
        a.target = "_blank";
        document.body.append(a);
        a.click();
    }

    return (
        <div className='downloadable'>
            <div className="grid lg:grid-cols-6">
                <div className='my-5 text-center mx-auto'>
                    <figure><img className='md:w-24 md:h-24 w-16 h-16' src={Application} alt={appSetting.downloadableName} /></figure>
                </div>
                <div className='my-auto lg:col-span-4'>
                    <div className='font-bold lg:text-start text-center px-5 text-blue-900'>{appSetting.downloadableName}</div>
                    {
                        appSetting.downloadableDescription !== undefined && appSetting.downloadableDescription !== '' ?
                        <div className='lg:text-start px-5 text-center text-blue-900'>{appSetting.downloadableDescription}</div> : null
                    }                    
                </div>
                <div className='my-5'>
                    <div className="flex relative mb-2">
                        <button type="submit" className="file-button" onClick={() => downloadFile()}>
                            <span className='text-xs mr-2'>DOWNLOAD</span>
                            <FontAwesomeIcon icon={solid('download')} />
                        </button>
                    </div>
                    {
                        loggedUser && loggedUser.canDelete ?
                            <div className="flex relative">
                                <button type="submit" className="file-button" onClick={() => openDeleteApplicationSettingModal()}>
                                    <span className='text-xs mr-2'>DELETE</span>
                                    <FontAwesomeIcon icon={solid('trash')} />
                                </button>
                            </div> : null
                    }
                </div>
            </div>
        </div>
    )
}

export default ApplicationSetting