import React, { useContext } from 'react'
import { UsersContext } from '../../Contexts/UsersContext'

const DeleteUserModal = () => {

    const { selectedUser, deleteUser, errorMessage } = useContext(UsersContext);

    return (
        <div>
            <input type="checkbox" id="deleteUserModal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box w-11/12 max-w-5xl bg-blue-900">
                    <h3 className="font-bold text-lg mb-5 text-vangold">DELETE USER</h3>
                    <div>
                        <h3 className="font-bold text-md mb-5 text-white">YOU ARE ABOUT TO DELETE THIS USER</h3>
                        <h3 className="text-md mb-5 text-white">ARE YOU SURE ?</h3>
                    </div>
                    <div className="modal-action">
                        <div htmlFor="deleteUserModal" className="modal-action-btn" onClick={() => deleteUser()}>DELETE</div>
                        <label htmlFor="deleteUserModal" className="modal-action-btn">CLOSE</label>
                    </div>
                </div>
            </div>
            {
                errorMessage !== '' ?
                    <div className='lg:col-start-2 p-5 my-auto text-center font-bold text-vangold'>{errorMessage}</div> : null
            }
        </div>
    )
}

export default DeleteUserModal