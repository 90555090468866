import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { CategoriesContext } from '../../Contexts/CategoriesContext'
import { ProductsContext } from '../../Contexts/ProductsContext';
import Products from '../Products/Products';
import { UsersContext } from '../../Contexts/UsersContext';

function Category({ category }) {

    const { loggedUser } = useContext(UsersContext);
    const { setSelectedCategory } = useContext(CategoriesContext);
    const { setCategoryID } = useContext(ProductsContext);

    const openModal = () => {

        document.getElementById('my-modal-6').checked = true;
        setCategoryID(category.categoryID);
    }

    const openCategoryVisibilityModal = () => {

        setSelectedCategory(category);
        document.getElementById('hideShowCategoryModal').checked = true;
    }

    const openDeleteCategoryModal = () => {

        setSelectedCategory(category);
        document.getElementById('deleteCategoryModal').checked = true;
    }

    const openEditCategoryModal = () => {

        setSelectedCategory(category);
        document.getElementById('editCategoryModal').checked = true;
    }

    return (
        <div className='bg-transparent p-5 mx-10 my-5 drop-shadow-md'>
            <div className='md:grid md:grid-cols-2'>
                <div className='my-5 md:text-start text-center font-bold text-2xl text-black'>{category.categoryName}</div>
                {
                    loggedUser ?
                        <div className='grid grid-cols-4 my-5'>
                            {
                                loggedUser.canUpdate ?
                                category.isHidden ?
                                    <div className='md:text-end text-center'>
                                        <FontAwesomeIcon icon={solid('eye')} className="text-black hover:scale-150 duration-200 hover:cursor-pointer hover:text-slate-600" onClick={() => openCategoryVisibilityModal()} />
                                    </div>
                                    :
                                    <div className='md:text-end text-center'>
                                        <FontAwesomeIcon icon={solid('eye-slash')} className="text-black hover:scale-150 duration-200 hover:cursor-pointer hover:text-slate-600" onClick={() => openCategoryVisibilityModal()} />
                                    </div>
                                    : null
                            }
                            {
                                loggedUser.canDelete ?
                                    <div className='md:text-end text-center'>
                                        <FontAwesomeIcon icon={solid('trash')} className="text-black hover:scale-150 duration-200 hover:cursor-pointer hover:text-slate-600" onClick={() => openDeleteCategoryModal()} />
                                    </div> : null
                            }
                            {
                                loggedUser.canUpdate ?
                                    <div className='md:text-end text-center'>
                                        <FontAwesomeIcon icon={solid('pen-to-square')} className="text-black hover:scale-150 duration-200 hover:cursor-pointer hover:text-slate-600" onClick={() => openEditCategoryModal()} />
                                    </div> : null
                            }
                            {
                                loggedUser.canCreate ?
                                    <div className='md:text-end text-center'>
                                        <FontAwesomeIcon icon={solid('plus')} className="text-black hover:scale-150 duration-200 hover:cursor-pointer hover:text-slate-600" onClick={() => openModal()} />
                                    </div> : null
                            }
                        </div> : null
                }
            </div>
            <div className='text-start my-5'>{category.categoryDescription}</div>
            <Products products={category.products} />
        </div>
    )
}

export default Category